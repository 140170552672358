import { gql } from "@apollo/client";
import { DropdownType } from "./fragments/DropdownType";
import { LeaveDeductionType } from "./fragments/LeaveDeductionType";
import { LeaveTypesType } from "./fragments/LeaveTypesType";
import { PaginationType } from "./fragments/PaginationType";

export const LOAD_USERS = gql`
  query {
    getUnion {
      upazilla_name
      upazilla_id
      name
      id
    }
  }
`;
export const LOAD_DIVISION = gql`
  query {
    getDivision {
      name
      id
    }
  }
`;
export const LOAD_DISTRICT = gql`
  query getDistrictById($division_id: Int) {
    getDistrictById(division_id: $division_id) {
      name
      id
      division_id
    }
  }
`;

export const LOAD_UPAZILLA = gql`
  query getUpazillaById($district_id: Int) {
    getUpazillaById(district_id: $district_id) {
      name
      id
      district_id
      district_name
    }
  }
`;
export const LOAD_UNION = gql`
  query getUnionById($upazilla_id: Int) {
    getUnionById(upazilla_id: $upazilla_id) {
      id
      name
      upazilla_name
    }
  }
`;
export const ALL_DISTRICT = gql`
  query {
    getDistrict {
      name
      id
      division_id
    }
  }
`;
export const ALL_UPAZILLA = gql`
  query {
    getUpazilla {
      name
      district_id
      id
    }
  }
`;

export const ALL_UNION = gql`
  query {
    getUnion {
      name
      id
      upazilla_id
    }
  }
`;
export const LOAD_ALL_FARMER = gql`
  query getAllFarmer(
    $page: Int
    $size: Int
    $search: String
    $divisionId: String
    $districtId: String
    $upazillaId: String
    $unionId: String
  ) {
    getAllGeneric(
      page: $page
      size: $size
      search: $search
      districtId: $districtId
      divisionId: $divisionId
      upazillaId: $upazillaId
      unionId: $unionId
    ) {
      count
      rows {
        name
        farmerId
        id
        division
        district
        upazilla
        union
        village
        image
        sex
        spouse_or_father
        mobile
        nid
        male_members
        female_members
        total_members
        school_going
        family_type
        education_level
        mobile
        experience
        status
        createdAt
      }
    }
  }
`;

export const LOAD_FARMER = gql`
  query getGenericInfo($id: ID) {
    getGenericInfo(id: $id) {
      name
      agent_id
      age
      farmerId
      status
      id
      division
      district
      upazilla
      union
      village
      image
      sex
      spouse_or_father
      mobile
      nid
      male_members
      female_members
      total_members
      school_going
      family_type
      education_level
      mobile
      experience
      farmerDigital {
        status
        gen_id
        member_of_farmer_club
        time_spending
        use_internet
        type_of_phone
        pass_free_time {
          name
        }
      }
      agent {
        id
        name
        username
      }
      finance {
        gen_id
        id
        land_area
        mouja
        khotiyan
        daag
        own_leases
        occupation {
          name
          incomes
        }
        finance_support {
          name
        }
      }
      farmerProblem {
        gen_id
        cold_storage
        comment_question
        problems {
          name
        }
      }
      farmingPractice {
        id
        farming_year
        cultivated_areas
        seeds_collection
        profit
        sell_product
        selling_price
        production_cost
        current_price
        weeds
        season
        risk_of_flood
        training
        spray_time
        crop
        variety
        company
        price_unit
        fertilizer {
          name
          value
        }
        disease_pests_dose {
          disease
          pest
          dose
          dose_unit
        }
      }
    }
  }
`;

export const LOAD_FINANCES = gql`
  query getFinance($gen_id: ID) {
    getFinanceInfo(gen_id: $gen_id) {
      gen_id
      land_area
      own_leases
      occupation {
        name
        incomes
      }
      finance_support {
        name
      }
    }
  }
`;

export const LOAD_FARMERS_PROBLEM = gql`
  query getFarmersProblemInfo($gen_id: ID) {
    getFarmerProblemInfo(gen_id: $gen_id) {
      gen_id
      cold_storage
      comment_question
      problems {
        name
      }
      status
      result_code
    }
  }
`;

export const getAgentInfo = gql`
  query getAgentInfo($id: ID) {
    getInfo(id: $id) {
      name
      id
    }
  }
`;
export const ALL_CATEGORY = gql`
  query {
    getCropCategory {
      count
      rows {
        id
        category_name
        category_code
      }
    }
  }
`;
export const CROP_LIST = gql`
  query {
    getAllCrops {
      count
      rows {
        id
        crop_name
        scientific_name
        image
        crop_family
        general_info
        crop_category
        irrigation {
          crop_id
          description
        }
        climate {
          crop_id
          climate_ph_end
          climate_ph_start
          climate_rainfall_start
          climate_rainfall_end
          climate_temperature_start
          climate_temperature_end
          climate_humidity
          general_info
          land_type
        }
        seed {
          crop_id
          showing_method
          time_showing
          seedbed
          seed_rate
          treatment
        }
        variety {
          id
          variety_name
          variety_yield
          variety_duration
          seasons {
            season
          }
          special_character
        }
        fertilizer {
          fertilizer
        }
        landPreparation {
          description
        }
      }
    }
  }
`;
export const GET_CROP_INFO = gql`
  query getCropInfo($id: ID) {
    getCropInfo(id: $id) {
      id
      creator_id
      crop_name
      scientific_name
      image
      crop_family
      general_info
      crop_category
      irrigation {
        crop_id
        description
      }
      climate {
        crop_id
        climate_ph_end
        climate_ph_start
        climate_rainfall_start
        climate_rainfall_end
        climate_temperature_start
        climate_temperature_end
        climate_humidity
        general_info
        land_type
      }
      pesticide {
        id
        crop_id
        control_measure
        damage_control
        disease_type
        image {
          image
        }
        chemical {
          pesticide_id
          dose
          mixing
          price
          unit
          company_name
          group_name
          trade_name
        }
        disease_name
      }
      seed {
        crop_id
        showing_method

        seedbed
        seed_rate
        treatment
      }
      variety {
        id
        variety_name
        variety_yield
        variety_duration
        seasons {
          season
        }
        special_character
        time_showing
      }
      fertilizer {
        fertilizer
      }
      landPreparation {
        description
      }
      harvest {
        description
      }
      intercultural {
        description
      }
    }
  }
`;

// user information
export const GET_INFO = gql`
  query getInfo($id: ID) {
    getInfo(id: $id) {
      name
      username
      images
      phone
      district
      division
      upazilla
    }
  }
`;
// get agent information
export const GET_AGENT_INFO = gql`
  query getAgentInfo($id: ID) {
    getAgentInfo(id: $id) {
      id
      name
      username
      images
      password
      phone
      district
      division
      upazilla
      email
    }
  }
`;

// get all TrainerInfo
export const GET_TRAINER_INFO = gql`
  query {
    getAllTraining {
      status
      count
      rows {
        id
        trainer_name
        training_title
        training_description
        date
      }
    }
  }
`;
// get all post and comments
export const GET_ALL_POST = gql`
  query getAllPost($page: Int, $size: Int, $search: String) {
    getAllPost(page: $page, size: $size, search: $search) {
      count
      rows {
        id
        agent_id
        category
        createdAt
        description
        images {
          images
        }
        comment {
          agent_id
          post_id
          createdAt
          comments
          status
          result_code
          agent {
            id
            name
            images
          }
        }
        status
        result_code
      }
    }
  }
`;

export const GET_PARTICIPANT_BY_ID = gql`
  query getParticipant($training_id: Int) {
    getAllParticipants(training_id: $training_id) {
      name
      id
      district
      division
      upazilla
      union
      mobile
      farmerId
      image
    }
  }
`;

export const GET_UNITS = gql`
  query {
    getUnit {
      count
      rows {
        unit_name
        unit_code
        id
      }
    }
  }
`;
// get post by id
export const GET_POST_BY_ID = gql`
  query getPostById($post_id: Int) {
    getPostById(post_id: $post_id) {
      id
      agent_id
      description
      category
      createdAt
      images {
        images
      }
      agent {
        id
        name
      }
      comment {
        agent_id
        post_id
        createdAt
        comments
        status
        result_code
      }
      status
    }
  }
`;

// get all request category
export const GET_ALL_REQUEST_CATEGORY = gql`
  query requestCategoryList {
    requestCategoryList {
      rows {
        category_name
        category_code
        id
      }
    }
  }
`;
//get all pesticide query;
export const GET_ALL_PESTICIDE = gql`
  query {
    pesticideList {
      count
      rows {
        id
        crop_id
        control_measure
        damage_control
        disease_type
        image {
          image
        }
        chemical {
          pesticide_id
          dose
          mixing
          price
          unit
          company_name
          group_name
          trade_name
        }
        disease_name
      }
    }
  }
`;
export const GET_CROP_CATEGORY = gql`
  query {
    getCropCategory {
      count
      rows {
        id
        category_name
        category_code
      }
    }
  }
`;

export const ALL_HUB = gql`
  query {
    getHubs {
      count
      rows {
        id
        hub_name
        hub_description
        banner_image
        division
        district
        union
        upazilla
        village
      }
    }
  }
`;

export const GET_HUB_PARTICIPANTS = gql`
  query getHubParticipant($hub_id: Int) {
    getHubParticipant(hub_id: $hub_id) {
      id
      name
      division
      district
      village
      union
      image
      mobile
      farmerId
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query {
    getPermissions {
      id
      permission_name
    }
  }
`;

export const GET_ALL_ROLE = gql`
  query {
    getRoles {
      id
      role_name
      permissions {
        permission_name
      }
    }
  }
`;

export const GET_ALL_USER = gql`
  query {
    getUser {
      id
      division
      district
      upazilla
      union
      name
      username
      phone
      email
      password
      gender
      village
      images
      role {
        id
        role_name
        permissions {
          permission_name
        }
      }
      additionalInfo {
        user_id
        rfid
        dept_id
        desig_id
        description
        joining_date

        reward
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }
      unionInfo {
        id
        name
      }
      upazillaInfo {
        id
        name
      }
      districtInfo {
        id
        name
      }
      divisionInfo {
        id
        name
      }
      companyCollaborationInfo {
        id
        company_id
        office_id
        companyCollaborationCompanyInfo {
          name
        }
      }
    }
  }
`;
export const GET_ALL_USER_BY_ATTENDANCE = gql`
  query getUserByAttendance($login_date: String) {
    getUserByAttendance(login_date: $login_date) {
      id
      division
      district
      upazilla
      union
      name
      username
      phone
      email
      gender
      village
      images
      role {
        id
        role_name
        permissions {
          permission_name
        }
      }
      additionalInfo {
        user_id
        rfid
        dept_id
        desig_id
        description
        joining_date
        reward
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }
      unionInfo {
        id
        name
      }
      upazillaInfo {
        id
        name
      }
      districtInfo {
        id
        name
      }
      divisionInfo {
        id
        name
      }
      attendanceInfo {
        id
        rfid
        user_ip
        useragent
        login_date
        login_time
        logout_time
        login_device
        logout_device
        isAuthorized
        reason
        type
        createdAt
        updatedAt
      }
    }
  }
`;
export const GET_USER_ATTENDANCE = gql`
  query getUserAttendance($login_date: String) {
    getUserByAttendance(login_date: $login_date) {
      id
      name
      username
      phone
      email
      images
      additionalInfo {
        user_id
        rfid
        dept_id
        desig_id
        description
        joining_date
        reward
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }
      attendanceInfo {
        id
        rfid
        user_ip
        useragent
        login_date
        login_time
        logout_time
        login_device
        logout_device
        isAuthorized
        reason
        type
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: Int) {
    getUserById(id: $id) {
      id
      status
      name
      username
      union
      division
      district
      upazilla
      phone
      village
      password
      images
      email
      gender
      divisionInfo {
        name
      }
      unionInfo {
        name
        upazilla_name
      }
      upazillaInfo {
        name
      }
      districtInfo {
        name
      }
      additionalInfo {
        id
        user_id
        dept_id
        desig_id
        description
        rfid
        casual
        sick
        anual
        maternity
        menstrual
        reward
        joining_date
      }
      companyCollaborationInfo {
        id
        company_id
        office_id
        companyCollaborationCompanyInfo {
          name
        }
      }
    }
  }
`;
export const GET_USER_BY_USERID = gql`
  query getUserByUserid($id: Int) {
    getUserByUserid(id: $id) {
      id
      name
      username
      phone
      email
      gender
      village
      images

      jobType
      EmployeeClassification
      workingTime
      qualification
      documents
      isActive
      role {
        id
        role_name
        permissions {
          permission_name
        }
      }
      additionalInfo {
        user_id
        reward
        rfid
        office_id
        dept_id
        desig_id
        description
        joining_date
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }

      companyCollaborationInfo {
        id
        company_id
        office_id
        companyCollaborationCompanyInfo {
          name
        }
      }
      unionInfo {
        id
        name
      }
      upazillaInfo {
        id
        name
      }
      districtInfo {
        id
        name
      }
      divisionInfo {
        id
        name
      }
    }
  }
`;

// department
export const GET_ALL_DEPARTMENT = gql`
  query {
    getAllDepartments {
      id
      name
      description
      user_id
      user {
        id
        name
        username
        email
        additionalInfo {
          rfid
        }
      }
    }
  }
`;
export const GET_DEPARTMENTS_BY_USER_ID = gql`
  query getDepartmentByUserId($user_id: Int) {
    getDepartmentByUserId(user_id: $user_id) {
      id
      name
      description
      user_id
      user {
        id
        name
        username
        email
        additionalInfo {
          rfid
        }
      }
    }
  }
`;

// designation queries
export const GET_ALL_DESIGNATION = gql`
  query {
    getAllDesignation {
      id
      name
      dep_id
      description
      departmentInfo {
        name
      }
      status
      result_code
    }
  }
`;

export const GET_DESIGNATION_BY_ID = gql`
  query getDesignationById($id: Int) {
    getDesignationById(id: $id) {
      id
      name
      description
      status
      result_code
    }
  }
`;

// leaves
export const GET_ALL_LEAVES = gql`
  query getAllLeaves {
    getAllLeaves {
      id
      start_date
      user_id
      images
      rfid
      end_date
      leave_type
      leave_status
      approve_by
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          dept_id
          sick
          anual
          casual
          user_id
          rfid
          joining_date
          menstrual
          maternity
          reward
        }
      }
      approvedUser {
        name
        images
        email
        username
      }
    }
  }
`;

export const GET_ALL_LEAVES_BETWEEN_DATES = gql`
  query getAllLeavesBetweenDates($startDate: String, $endDate: String) {
    getAllLeavesBetweenDates(startDate: $startDate, endDate: $endDate) {
      id
      start_date
      user_id
      images
      rfid
      end_date
      leave_type
      leave_status
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          dept_id
          sick
          anual
          casual
          user_id
          rfid
          joining_date
          menstrual
          maternity
          reward
        }
      }
    }
  }
`;

export const GET_ALL_LEAVES_BY_USER_ID = gql`
  query getAllLeavesByUserId($user_id: Int) {
    getAllLeavesByUserId(user_id: $user_id) {
      id
      start_date
      user_id
      end_date
      rfid
      leave_type
      leave_status
      images
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          sick
          anual
          casual
          dept_id
          user_id
          joining_date
          menstrual
          maternity
          reward
        }
      }
    }
  }
`;
export const CHECK_PREVIOUS_LEAVE_BY_LEAVE_ID_AND_USER_ID = gql`
  query checkPreviousLeaveByLeaveIdAndUserId($user_id: Int, $id: Int) {
    checkPreviousLeaveByLeaveIdAndUserId(user_id: $user_id, id: $id) {
      id
      start_date
      user_id
      end_date
      rfid
      leave_type
      leave_status
      images
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          sick
          anual
          casual
          dept_id
          user_id
          joining_date
          menstrual
          maternity
          reward
        }
      }
    }
  }
`;
export const GET_ALL_PENDING_LEAVES_BY_DEPT_ID = gql`
  query pendingLeavesByDeptId($dept_id: Int) {
    pendingLeavesByDeptId(dept_id: $dept_id) {
      id
      start_date
      user_id
      end_date
      rfid
      leave_type
      leave_status
      images
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          sick
          anual
          casual
          dept_id
          user_id
          joining_date
          menstrual
          maternity
          reward
        }
      }
    }
  }
`;
export const GET_ALL_LEAVES_BY_RFID = gql`
  query getAllLeavesByRfid($rfid: String) {
    getAllLeavesByRfid(rfid: $rfid) {
      id
      start_date
      user_id
      end_date
      rfid
      leave_type
      leave_status
      images
      leave_reason
      deny_reason
      createdAt
      updatedAt
      user {
        name
        images
        email
        username
        additionalInfo {
          sick
          anual
          casual
          dept_id
          user_id
          joining_date
          menstrual
          maternity
          reward
        }
      }
    }
  }
`;

// additional info

export const GET_ALL_ADDITIONAL_INFO = gql`
  query getAllAdditionalInfo {
    getAllAdditionalInfo {
      id
      user_id
      reward
      dept_id
      desig_id
      description
      joining_date
      casual
      anual
      sick
      menstrual
      maternity
      user {
        email
        name
        gender
        username
      }
    }
  }
`;

export const GET_ALL_ADDITIONAL_INFO_BY_USER_ID = gql`
  query getAllAdditionalInfoByUserId($user_id: Int) {
    getAllAdditionalInfoByUserId(user_id: $user_id) {
      id
      desig_id
      user_id
      dept_id

      rfid
      casual
      sick
      anual
      menstrual
      maternity
      user {
        name
        username
        email
      }
      designationInfo {
        name
        id
      }
    }
  }
`;
export const GET_ALL_ADDITIONAL_INFO_BY_RFID = gql`
  query getAllAdditionalInfoByRfid($rfid: String) {
    getAllAdditionalInfoByRfid(rfid: $rfid) {
      id
      desig_id
      dept_id
      casual
      sick

      rfid
      anual
      menstrual
      maternity
      user {
        name
        username
        email
      }
      designationInfo {
        name
        id
      }
    }
  }
`;
export const GET_ALL_ADDITIONAL_INFO_BY_DEPT_ID = gql`
  query getAllAdditionalInfoByDeptId($dept_id: Int) {
    getAllAdditionalInfoByDeptId(dept_id: $dept_id) {
      dept_id
      user_id

      rfid
      user {
        id
        name
        images
        username
        email
        phone
        divisionInfo {
          name
        }
        districtInfo {
          name
        }
        upazillaInfo {
          name
        }
        unionInfo {
          name
        }
      }
      designationInfo {
        name
      }
      departmentInfo {
        name
      }
    }
  }
`;
export const GET_REST_LEAVE_BY_USER_ID = gql`
  query getRestLeaveByUserId($user_id: Int) {
    getRestLeaveByUserId(user_id: $user_id) {
      user_id
      casual
      sick
      anual
      menstrual
      maternity
      reward
    }
  }
`;

// attendance

export const GET_ALL_ATTENDANCES = gql`
  query getAllAttendances {
    getAllAttendances {
      id
      rfid
      user_ip
      useragent
      login_date
      login_time
      logout_time
      login_device
      logout_device
      reason
      type
      createdAt
      updatedAt
      employeeInfo {
        user_id
        designationInfo {
          name
        }
        user {
          name
        }
      }
    }
  }
`;
export const GET_ATTENDANCE_BY_DATE_RFID = gql`
  query getAllAttendanceByDateAndRfid($user_id: Int, $login_date: String) {
    getAllAttendanceByDateAndRfid(user_id: $user_id, login_date: $login_date) {
      login_status
    }
  }
`;
export const GET_ALL_ATTENDANCES_BY_DATE = gql`
  query getAttendanceByDate {
    getAttendanceByDate {
      id
      user_ip
      rfid
      useragent
      login_date
      login_time
      logout_time
      reason
      type
      login_device
      logout_device
      createdAt
      updatedAt
      employeeInfo {
        user_id
        designationInfo {
          name
        }
        user {
          name
        }
      }
    }
  }
`;

export const SEARCH_ATTENDANCE_BETWEEN_DATE = gql`
  query search_attendance_between_date($startedDate: String, $endDate: String) {
    search_attendance_between_date(
      startedDate: $startedDate
      endDate: $endDate
    ) {
      id
      user_ip
      rfid
      useragent
      login_date
      login_time
      logout_time
      login_device
      reason
      type
      logout_device
      createdAt
      updatedAt
      employeeInfo {
        user_id
        designationInfo {
          name
        }
        user {
          name
        }
      }
    }
  }
`;
export const SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID = gql`
  query search_attendance_between_date_and_rfid(
    $startedDate: String
    $endDate: String
    $user_id: Int
  ) {
    search_attendance_between_date_and_rfid(
      startedDate: $startedDate
      endDate: $endDate
      user_id: $user_id
    ) {
      id
      user_ip
      user_id
      rfid
      useragent
      login_date
      login_time
      logout_time
      login_device
      isAuthorized
      reason
      type
      logout_device
      createdAt
      updatedAt
      employeeInfo {
        user {
          name
          username
        }
        user_id
        designationInfo {
          name
        }
      }
    }
  }
`;
// checking rfid

export const EIXST_RFID = gql`
  query existRFID($rfid: String) {
    existRFID(rfid: $rfid) {
      status
      result_code
      rfid
    }
  }
`;

// project proposal
export const GET_ALL_PROJECT_PROPOSAL_BY_CUSTOMER_ID = gql`
  query projectProposalByCustomer($customer_id: Int) {
    projectProposalByCustomer(customer_id: $customer_id) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_ALL_PROJECT_PROPOSAL_BY_DEPT_ID = gql`
  query projectProposalByDeptId($dept_id: Int) {
    projectProposalByDeptId(dept_id: $dept_id) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_ALL_PROJECT_PROPOSAL_BY_DEPT_ID_STATUS = gql`
  query projectProposalByDeptIdStatus($dept_id: Int, $projectStatus: String) {
    projectProposalByDeptIdStatus(
      dept_id: $dept_id
      projectStatus: $projectStatus
    ) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_PROJECT_PROPOSAL_BY_CUSTOMER_ID_STATUS = gql`
  query projectProposalByCustomerAndStatus(
    $customer_id: Int
    $projectStatus: String
  ) {
    projectProposalByCustomerAndStatus(
      customer_id: $customer_id
      projectStatus: $projectStatus
    ) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_ALL_PROJECT_PROPOSAL_BY_STATUS = gql`
  query projectProposalByStatus($projectStatus: String) {
    projectProposalByStatus(projectStatus: $projectStatus) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_ALL_PROJECT_PROPOSAL = gql`
  query projectProposal {
    projectProposal {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;
export const GET_ALL_PROJECT_PROPOSAL_BY_INCLUDE_DEPT_HEAD = gql`
  query getAllProjectProposalByIndividualHeadpDept($user_id: Int) {
    getAllProjectProposalByIndividualHeadpDept(user_id: $user_id) {
      id
      customer_id
      title
      start
      end
      createdAt
      projectStatus
      images
      description
      project_type
      service_type
      dept_id
      client_type
      updatedAt
      projectProposalDeptInfo {
        name
        user {
          name
          id
        }
      }
      customerInfo {
        fullname
        phone
        address
        email
      }
    }
  }
`;

// service type
export const GET_ALL_SERVICE_TYPE = gql`
  query getAllServiceType {
    getAllServiceType {
      id
      name
      description
    }
  }
`;
export const GET_SERVICE_TYPE_BY_ID = gql`
  query getServiceTypeById($id: Int) {
    getServiceTypeById(id: $id) {
      name
      description
    }
  }
`;

// project proposal comments
export const GET_PROJECT_PROPOSAL_COMMENTS_BY_PROPOSAL_ID = gql`
  query projectProposalCommentsByProposalId($project_proposal_id: Int) {
    projectProposalCommentsByProposalId(
      project_proposal_id: $project_proposal_id
    ) {
      id
      customer_id
      attachment
      comment
      createdAt
      updatedAt
      projectProposalWithCommentInfo {
        customerInfo {
          fullname
          email
          image
          avator
        }
      }
    }
  }
`;

// Holydays
export const GET_ALL_HOLYDAYS = gql`
  query getAllHolydays {
    getAllHolydays {
      id
      name
      start_date
      end_date
      year
    }
  }
`;
export const GET_ALL_HOLYDAYS_BY_YEAR = gql`
  query getAllHolydaysByYear($year: Int) {
    getAllHolydaysByYear(year: $year) {
      id
      name
      start_date
      end_date
      year
    }
  }
`;

// penalty
export const GET_ALL_PENALTY = gql`
  query getAllPenalty {
    getAllPenalty {
      id
      user_id
      penalty_status
      penalty_date
      created_by
      leave_id
      reviewPenaltyInfo {
        id
        attendance_type
        denied_reason
        created_by
        attendance_reason
        penaltyReviewUserInfo {
          name
          email
          username
        }
        createdAt
        updatedAt
      }
      penaltyUserInfo {
        name
        email
        images
        additionalInfo {
          dept_id
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const GET_ALL_PENALTY_BY_USER = gql`
  query getAllPenaltyByUser($user_id: Int) {
    getAllPenaltyByUser(user_id: $user_id) {
      id
      user_id
      penalty_status
      penalty_date
      leave_id
      created_by
      reviewPenaltyInfo {
        id
        attendance_type
        denied_reason
        created_by
        attendance_reason
        penaltyReviewUserInfo {
          name
          email
          username
        }
        createdAt
        updatedAt
      }
      penaltyUserInfo {
        name
        email
        images
        additionalInfo {
          dept_id
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const GET_ALL_PENALTY_BY_USER_ID_AND_DATE = gql`
  query getAllPenaltyByUserIdAndDate($user_id: Int, $penalty_date: String) {
    getAllPenaltyByUserIdAndDate(
      user_id: $user_id
      penalty_date: $penalty_date
    ) {
      id
      user_id
      penalty_status
      penalty_date
      leave_id
      created_by
      reviewPenaltyInfo {
        id
        attendance_type
        denied_reason
        created_by
        attendance_reason
        penaltyReviewUserInfo {
          name
          email
          username
        }
        createdAt
        updatedAt
      }
      penaltyUserInfo {
        name
        email
        images
        additionalInfo {
          dept_id
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const GET_ALL_PENALTY_BY_DEPT = gql`
  query getAllPenaltyByDept($user_id: Int) {
    getAllPenaltyByDept(user_id: $user_id) {
      id
      user_id
      penalty_status
      penalty_date
      leave_id
      created_by
      reviewPenaltyInfo {
        id
        attendance_type
        denied_reason
        created_by
        attendance_reason
        penaltyReviewUserInfo {
          name
          email
          username
        }
        createdAt
        updatedAt
      }
      penaltyUserInfo {
        name
        email
        images
        additionalInfo {
          dept_id
        }
      }
      updatedAt
      createdAt
    }
  }
`;
export const GET_PENALTY_BY_ID = gql`
  query getPenaltyById($id: Int) {
    getPenaltyById(id: $id) {
      id
      user_id
      penalty_status
      penalty_date
      leave_id
      created_by
      reviewPenaltyInfo {
        id
        attendance_type
        denied_reason
        created_by
        attendance_reason
        penaltyReviewUserInfo {
          name
          email
          username
        }
        createdAt
        updatedAt
      }
      penaltyUserInfo {
        name
        email
        images
        additionalInfo {
          dept_id
        }
      }
      updatedAt
      createdAt
    }
  }
`;

// penalty review
export const GET_PENALTY_REVIEW_BY_PENALTY_ID = gql`
  query getPenaltyReviewByPenaltyId($penalty_id: Int) {
    getPenaltyReviewByPenaltyId(penalty_id: $penalty_id) {
      id
      penalty_id
      penaltyReviewInfo {
        id
        penalty_date
      }
    }
  }
`;

// company
export const GET_ALL_COMPANIES = gql`
  query {
    getAllCompanies {
      id
      name
      description
      logo
    }
  }
`;
export const LEAVE_TYPES = gql`
  ${LeaveTypesType}
  ${PaginationType}
  query leaveTypes($input: LeaveTypesInputType) {
    leaveTypes(input: $input) {
      data {
        ...LeaveTypesType
      }
      paginatorInfo {
        ...PaginationType
      }
      message
      status_code
    }
  }
`;
export const LEAVE_TYPE_DROPDOWNS = gql`
  ${DropdownType}
  ${PaginationType}
  query leaveTypeDropdowns($input: LeaveTypesInputType) {
    leaveTypeDropdowns(input: $input) {
      data {
        ...DropdownType
      }
      paginatorInfo {
        ...PaginationType
      }
      message
      status_code
    }
  }
`;
export const LEAVE_APPLICATION_DROPDOWNS = gql`
  ${DropdownType}
  query leaveApplicationDropdowns($input: LeaveApplicationDropdownsInputType) {
    leaveApplicationDropdowns(input: $input) {
      leaveTypes {
        ...DropdownType
      }
      relievers {
        label
        value
      }
      message
      status_code
    }
  }
`;
export const USER_DROPDOWNS = gql`
  query userDropdowns($input: UserDropdownsTypeInputType) {
    userDropdowns(input: $input) {
      users {
        label
        value
      }
      message
      status_code
    }
  }
`;
export const DEPARTMENT_DROPDOWNS = gql`
  query departmentDropdowns($input: DepartmentDropdownsInputType) {
    departmentDropdowns(input: $input) {
      departments {
        label
        value
      }
      message
      status_code
    }
  }
`;
export const LEAVE_DEDUCTIONS = gql`
  ${PaginationType}
  ${LeaveDeductionType}
  query leaveDeductions($input: LeaveDeductionInputType) {
    leaveDeductions(input: $input) {
      data {
        ...LeaveDeductionType
      }
      paginatorInfo {
        ...PaginationType
      }
      message
      status_code
    }
  }
`;

export const LEAVE_DEDUCTION_BY_ID = gql`
  query leaveDeductionById($input: LeaveDeductionInputType!) {
    leaveDeductionById(input: $input) {
      data {
        id
        createdAt
        deductDays
        employee {
          id
          name
        }
        deductor {
          id
          name
        }
        leave_type {
          id
          name
        }
      }
      paginatorInfo {
        total
        currentPage
        lastPage
      }
      status_code
      message
    }
  }
`;
export const GET_WORKING_DAY = gql`
  query getWorkingDay($input: GetWorkingDayInputType) {
    getWorkingDay(input: $input) {
      weekends
      working_days
      holidays
      message
      status_code
    }
  }
`;

export const ATTENDANCE_SUMMARY = gql`
  query attendanceSummary($input: AttendanceSummaryInputType!) {
    attendanceSummary(input: $input) {
      user {
        id
        username
        gender
        name
        village
        images
        email
        phone
        unionInfo {
          name
          id
        }
        upazillaInfo {
          name
          id
        }
        districtInfo {
          name
          id
        }
        divisionInfo {
          name
          id
        }
        additionalInfo {
          office_id
          rfid
          joining_date
          casual
          anual
          sick
          menstrual
          maternity
          reward
          departmentInfo {
            name
            id
          }
          designationInfo {
            name
            id
          }
        }
      }
      totalWorkingDays
      attendanceTimeSlot {
        count
        range
        endTime
        startTime
        percentage
      }
      workingHour {
        total_work_hours
        working_hour_report {
          hours
          minutes
          seconds
        }
        working_hour_report_percentage
        overtime {
          hours
          minutes
          seconds
        }
        work_hour_report_history {
          id
          login_date
          login_time
          logout_time
          isLogout
          type
          working_hour_report {
            hours
            minutes
            seconds
          }
          overtime {
            hours
            minutes
            seconds
          }
        }
      }
      message
      status_code
    }
  }
`;
export const GET_ALL_ACTIVE_USER = gql`
  query {
    getAllActiveUsers {
      id
      division
      district
      upazilla
      union
      name
      username
      phone
      email
      password
      gender
      village
      images
      role {
        id
        role_name
        permissions {
          permission_name
        }
      }
      additionalInfo {
        user_id
        rfid
        dept_id
        desig_id
        description
        joining_date
        reward
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }
      unionInfo {
        id
        name
      }
      upazillaInfo {
        id
        name
      }
      districtInfo {
        id
        name
      }
      divisionInfo {
        id
        name
      }
      companyCollaborationInfo {
        id
        company_id
        office_id
        companyCollaborationCompanyInfo {
          name
        }
      }
    }
  }
`;
export const GET_INACTIVE_USER = gql`
  query {
    getInactiveUsers {
      id
      division
      district
      upazilla
      union
      name
      username
      phone
      email
      password
      gender
      village
      images
      role {
        id
        role_name
        permissions {
          permission_name
        }
      }
      additionalInfo {
        user_id
        rfid
        dept_id
        desig_id
        description
        joining_date
        reward
        casual
        sick
        anual
        menstrual
        maternity
        departmentInfo {
          name
        }
        designationInfo {
          name
        }
      }
      unionInfo {
        id
        name
      }
      upazillaInfo {
        id
        name
      }
      districtInfo {
        id
        name
      }
      divisionInfo {
        id
        name
      }
      companyCollaborationInfo {
        id
        company_id
        office_id
        companyCollaborationCompanyInfo {
          name
        }
      }
    }
  }
`;
export const GET_USERS = gql`
  query getUsers($page: Int, $size: Int, $search: String, $isActive: Boolean) {
    getUsers(page: $page, size: $size, search: $search, isActive: $isActive) {
      data {
        id
        agent_id
        user_id
        name
        username
        email
        message
        rfid
        phone
        department
        joined_date
        office_id
        images
        status
        is_active
        isLateForThisMonth
        job_type
        status_code
        deleted
        result_code
        designation
      }
      pagination {
        lastPage
        currentPage
        perPage
        total
      }
    }
  }
`;

export const GET_NOTICE = gql`
  query getNotice {
    getNotice {
      id
      name
      category
      description
      created_by
      createdAt
      notice_status
      user {
        id
        name
        email
      }
    }
  }
`;

export const GET_ACTIVE_NOTICE = gql`
  query GetActiveNotice {
    getActiveNotice {
      id
      name
      category
      description
      createdAt
      user {
        name
        email
      }
    }
  }
`;
export const GET_LATE_LOGINS = gql`
  query GetLateLogins($user_id: Int!) {
    getLateLogins(user_id: $user_id) {
      lateLogins {
        login_date
        login_time
        user_id
        isAuthorized
      }
      totalLateLoginsCount
    }
  }
`;

export const GET_LEAVE_DEDUCTIONS_BY_USER = gql`
  query getLeaveDeductionByUser($user_id: Int!) {
    getLeaveDeductionByUser(user_id: $user_id) {
      id
      employee_id
      deductor_id
      leave_type_id
      isLate
      days
      reason
      log_id
      createdAt
    }
  }
`;
export const GET_USER_BY_RFID = gql`
  query getUserByRfid($rfid: String!) {
    getUserByRfid(rfid: $rfid) {
      result_code
      status
      id
      name
      username
      additionalInfo {
        rfid
        departmentInfo {
          name
        }
      }
    }
  }
`;
