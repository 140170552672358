import { useForm } from "react-hook-form";
import { Button, Col } from "reactstrap";
import FormField from "./FormField";
import { SelectField } from "@/shared/components/form/Select";
import { useMutation } from "@apollo/client";
import { CREATE_ATTENDANCE_MANUAL } from "../../../GraphQL/Mutation";
import axios from "axios";
import moment from "moment";
import { successMessage } from "../../ReactToastify";
import { useEffect, useState } from "react";
import { loginInfo } from "../../../utils/helpers";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

const AttendanceForm = ({ toggle, allEmployees, refetch }) => {
  const theme = useSelector((state) => state.theme.className);
  console.log(theme);
  const {
    handleSubmit,
    reset,
    watch,
    register,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm();
  const loggedInfo = loginInfo();
  const [createAttendanceManual] = useMutation(CREATE_ATTENDANCE_MANUAL);
  const todayDate = moment(new Date()).format("YYYY-MM-DD");
  const [date, setDate] = useState(null);
  const [loginTime, setLoginTime] = useState(new Date(`${todayDate} 09:00:00`));
  const [logoutTime, setLogoutTime] = useState(
    new Date(`${todayDate} 17:00:00`)
  );

  // form validation
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({}); // form validation error message

  // for validation required message clear
  const errorMessageClear = (fieldName, dependency) => {
    useEffect(() => {
      if (fieldName) {
        setErrorMessage((prevState) => ({
          ...prevState,
          [fieldName]: "",
        }));
      }
    }, [dependency]);
  };
  errorMessageClear("date", date);
  errorMessageClear("loginTime", loginTime);
  errorMessageClear("logoutTime", logoutTime);
  errorMessageClear("reason", watch("reason"));
  errorMessageClear("userId", watch("user_id"));

  // form validation function
  const filedErrorMessage = (filedName, filedValue) => {
    if (filedValue === undefined || filedValue === null || filedValue === "") {
      setErrorMessage((prevState) => ({
        ...prevState,
        [filedName]: "This field is required!",
      }));
    }
  };
  // is form valid function
  const isFormValid = (value) => {
    filedErrorMessage("date", date);
    filedErrorMessage("loginTime", loginTime);
    filedErrorMessage("logoutTime", logoutTime);
    filedErrorMessage("userId", value?.user_id?.value);
    filedErrorMessage("reason", value?.reason);
    const isDateValid = date === undefined || date === null || date === "";
    const isLoginTimeValid =
      loginTime === undefined || loginTime === null || loginTime === "";
    const isLogoutTimeValid =
      logoutTime === undefined || logoutTime === null || logoutTime === "";
    const isReasonValid =
      watch("reason") === undefined ||
      watch("reason") === null ||
      watch("reason") === "";
    const isUserIdValid =
      watch("user_id") === undefined ||
      watch("user_id") === null ||
      watch("user_id") === "";
    const returnValue =
      [
        isDateValid,
        isLoginTimeValid,
        isLogoutTimeValid,
        isReasonValid,
        isUserIdValid,
      ]?.filter((item) => item != false)?.length > 0;
    if (!returnValue) {
      return true;
    } else {
      return false;
    }
  };

  const onSubmit = async (value) => {
    try {
      let returnValue;
      await axios
        .get("https://www.cloudflare.com/cdn-cgi/trace")
        .then(function (response) {
          returnValue = response?.data
            .trim()
            .split("\n")
            .reduce(function (obj, pair) {
              pair = pair.split("=");
              return (obj[pair[0]] = pair[1]), obj;
            }, {});
        });
      const formData = {
        user_id: value?.user_id?.value,
        login_date: moment(date).format("YYYY-MM-DD"),
        login_time: moment(loginTime).format("HH:mm:ss"),
        logout_time: moment(logoutTime).format("HH:mm:ss"),
        user_ip: returnValue?.ip,
        reason: value?.reason,
        created_user_id: loggedInfo?.id,
      };

      // submitting form if form is valid
      if (isFormValid(value)) {
        setIsLoading(true);
        const result = await createAttendanceManual({
          variables: formData,
        });
        if (result?.data?.createAttendanceManual?.result_code == "000") {
          refetch();
          toggle();
          setIsLoading(false);
          successMessage(result?.data?.createAttendanceManual?.status, 3000);
        }
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  return (
    <form
      className={`form ${
        theme === "theme-dark" ? "theme-dark" : "theme-light"
      }`}
      style={{ width: "100%" }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Col md={4}>
        <div className="form__form-group">
          <span className="form__form-group-label">{`Date`}</span>
          <div className="form__form-group-field leave-calender">
            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={date}
              placeholderText="dd/mm/yyyy"
              onChange={(date) => {
                setDate(date);
              }}
              showWeekNumbers
            />
          </div>
          {errorMessage?.date ? (
            <span className="text-danger">
              <b>{errorMessage?.date}</b>
            </span>
          ) : null}
        </div>
      </Col>
      <Col md={4}>
        <div className="form__form-group">
          <span className="form__form-group-label">{`Login Time`}</span>
          <div className="form__form-group-field">
            <DatePicker
              selected={loginTime}
              onChange={(date) => setLoginTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
          {errorMessage?.loginTime ? (
            <span className="text-danger">
              <b>{errorMessage?.loginTime}</b>
            </span>
          ) : null}
        </div>
      </Col>
      <Col md={4}>
        <div className="form__form-group">
          <span className="form__form-group-label">{`Logout Time`}</span>
          <div className="form__form-group-field">
            <DatePicker
              selected={logoutTime}
              onChange={(date) => setLogoutTime(date)}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              timeCaption="Time"
              dateFormat="h:mm aa"
            />
          </div>
          {errorMessage?.logoutTime ? (
            <span className="text-danger">
              <b>{errorMessage?.logoutTime}</b>
            </span>
          ) : null}
        </div>
      </Col>
      <Col md={12}>
        <div className="form__form-group">
          <span className="form__form-group-label">Employee</span>
          <div className="form__form-group-field">
            <FormField
              name="user_id"
              control={control}
              component={SelectField}
              options={allEmployees}
              placeholder="Select Employee"
            />
          </div>
          {errorMessage?.userId ? (
            <span className="text-danger">
              <b>{errorMessage?.userId}</b>
            </span>
          ) : null}
        </div>
      </Col>
      <Col md={12}>
        <div className="form__form-group">
          <label className="form__form-group-label">Reason</label>
          <div className="form__form-group-field">
            <FormField
              name="reason"
              component="textarea"
              control={control}
              type="text"
              rules={{
                maxLength: {
                  value: 250,
                  message: "Reason must be less than 250 characters.",
                },
                validate: (value) =>
                  value.length <= 250 ||
                  "Reason must be less than 250 characters.",
              }}
              errors={errors}
              placeholder={`Enter reason`}
            />
          </div>
          {errorMessage?.reason ? (
            <span className="text-danger">
              <b>{errorMessage?.reason}</b>
            </span>
          ) : null}
        </div>
      </Col>
      <Col md={12}>
        {isLoading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className="spinner-border text-secondary"
              role="status"
              style={{ width: "1rem", height: "1rem" }}
            >
              {/* <span className="visually-hidden">Loading...</span> */}
            </div>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button onClick={() => toggle()} type="button">
              Cancel
            </Button>
            <Button color="primary" type="submit" className="ml-2">
              Attendance
            </Button>
          </div>
        )}
      </Col>
    </form>
  );
};

export default AttendanceForm;
