import React, { useState } from "react";
import {
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Input,
  Row,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import ReactQuill CSS
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import {
  CREATE_COMPANY,
  CREATE_NOTICE,
  UPDATE_COMPANY,
  UPDATE_NOTICE,
} from "../../../../GraphQL/Mutation";
import Swal from "sweetalert2";
import BASE_URL from "../../../../utils/api/baseUrl";
import images from "@/shared/img/uploader/placeholder.png";
import { SelectField } from "@/shared/components/form/Select";
import axios from "axios";
import FormField from "../FormField";
import { loginInfo } from "../../../../utils/helpers";
import BreadCrumb from "../../../../shared/components/BreadCrumb/BreadCrumb";

const NewNotice = () => {
  const history = useHistory();
  const updateData = history?.location?.state?.notice;

  const [isLoading, setIsLoading] = useState(false);
  const loggedInfo = loginInfo();
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }], // Add color and background color options
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "align",
    "color", // Enable color
    "background", // Enable background color
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
  ];

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  const [createNotice, createNoticeStatus] = useMutation(CREATE_NOTICE);
  const [updateNotice, updateNoticeStatus] = useMutation(UPDATE_NOTICE);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const noticeData = {
      name: data?.name,
      category: data?.category?.value,
      description: data?.description,
      created_by: loggedInfo?.id,
      notice_status: data?.noticeStatus?.value,
    };
    const updateNoticeData = {
      id: Number(updateData?.id),
      name: data?.name,
      category: data?.category?.value,
      description: data?.description,
      created_by: loggedInfo?.id,
      notice_status: data?.noticeStatus?.value,
    };

    if (updateData?.id) {
      // update holiday
      const result = await updateNotice({
        variables: updateNoticeData,
      });
      if (result?.data?.updateNotice?.result_code == "000") {
        Swal.fire("Updated!", "Notice has been updated.", "success");
        // redirect to holydays list
        setIsLoading(false);
        history.push("/notice/list");
      }
      if (result?.data?.updateNotice?.result_code == "002") {
        Swal.fire(result?.data?.updateNotice?.status, "", "error");
        setIsLoading(false);
      }
    } else {
      setIsLoading(true);
      const result = await createNotice({
        variables: noticeData,
      });
      if (result?.data?.createNotice?.result_code == "000") {
        Swal.fire("Created!", "Notice has been created.", "success");
        setIsLoading(false);
        history.push("/notice/list");
      }
      if (result?.data?.createNotice?.result_code == "002") {
        setIsLoading(false);
        Swal.fire(result?.data?.createNotice?.status, "", "error");
      }
    }
  };

  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: updateData?.id ? "Update User" : "Create User",
    },
  ];

  // const [updateNotice] = useMutation(UPDATE_NOTICE, {
  //   onCompleted: (data) => {
  //     if (data.updateNotice.result_code === "000") {
  //       Swal.fire({
  //         icon: "success",
  //         title: "Success",
  //         text: "Notice updated successfully!",
  //       });
  //     } else {
  //       Swal.fire({
  //         icon: "error",
  //         title: "Error",
  //         text: data.updateNotice.status,
  //       });
  //     }
  //   },
  //   onError: (error) => {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "System error. Please contact the system admin.",
  //     });
  //     console.error("Error updating notice:", error);
  //   },
  // });

  return (
    <Col md={12}>
      <Row>
        <Col>
          <BreadCrumb breadCrumbData={breadCrumbData} />
        </Col>
      </Row>

      <Card>
        <CardBody>
          <Col md={12}>
            <h3 className="page-title">
              {updateData?.id ? "Update" : "Create"} Notice
            </h3>
          </Col>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={6} className="mx-auto">
              <Row>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Notice Name
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="name"
                        component="input"
                        control={control}
                        rules={{ required: "This is required field" }}
                        type="text"
                        defaultValue={updateData?.name}
                        placeholder={`Enter notice name`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Notice Category
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="category"
                        control={control}
                        component={SelectField}
                        options={[
                          {
                            value: "Official",
                            label: "Official",
                          },
                          {
                            value: "Unofficial",
                            label: "Unofficial",
                          },
                        ]}
                        defaultValue={[
                          {
                            value: "Official",
                            label: "Official",
                          },
                          {
                            value: "Unofficial",
                            label: "Unofficial",
                          },
                        ].find((item) => item?.value == updateData?.category)}
                        rules={{ required: "This is required field" }}
                        // value="dhjs"

                        placeholder="Select category"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Status</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="noticeStatus"
                        control={control}
                        component={SelectField}
                        options={[
                          {
                            value: "pending",
                            label: "Pending",
                          },
                          {
                            value: "active",
                            label: "Active",
                          },
                          {
                            value: "inActive",
                            label: "InActive",
                          },
                        ]}
                        defaultValue={[
                          {
                            value: "pending",
                            label: "Pending",
                          },
                          {
                            value: "active",
                            label: "Active",
                          },
                          {
                            value: "inActive",
                            label: "inActive",
                          },
                        ].find(
                          (item) => item?.value == updateData?.notice_status
                        )}
                        rules={{ required: "This is required field" }}
                        // value="dhjs"

                        placeholder="Select Status"
                      />
                    </div>
                  </div>
                </Col>

                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Description
                    </label>
                    <div>
                      <Controller
                        name="description"
                        control={control}
                        defaultValue={updateData?.description || ""}
                        rules={{ required: "This is a required field" }}
                        render={({ field: { onChange, value } }) => (
                          <ReactQuill
                            value={value}
                            onChange={onChange}
                            modules={modules}
                            formats={formats}
                            theme="snow"
                            placeholder="Enter description"
                          />
                        )}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "50px",
                    }}
                  >
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/notice/list")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>

                      <Button
                        disabled={isLoading}
                        color="primary"
                        type="submit"
                      >
                        {updateData?.id ? "Update" : `Publish`}
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewNotice;
