import React, { useEffect } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Stack from "@mui/material/Stack";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { TextField } from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { CREATE_HOLYDAY, UPDATE_HOLYDAY } from "../../../GraphQL/Mutation";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";

const HolydayForm = () => {
  // hooks
  const history = useHistory();
  const { handleSubmit, control, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  // variables
  const updateData = history.location.state?.holyday;
  console.log(updateData);
  // states
  const [startDate, setStartDate] = useState(
    updateData?.start_date ? new Date(updateData?.start_date) : new Date()
  );
  const [endDate, setEndDate] = useState(
    updateData?.end_date ? new Date(updateData?.end_date) : new Date()
  );
  const [isFormError, setIsFormError] = useState({
    message: "",
  });

  // mutations
  const [createHolyday, createHolydayStatus] = useMutation(CREATE_HOLYDAY);
  const [updateHolyday, updateHolydayStatus] = useMutation(UPDATE_HOLYDAY);

  // clear error message if change any filed values
  useEffect(() => {
    setIsFormError({
      message: "",
    });
  }, [watch("name")]);

  // submit form
  const onSubmit = async (value) => {
    setIsLoading(true);
    if (!value?.name) {
      setIsFormError({
        message: "This is required field",
      });
      setIsLoading(false);
      return false;
    }
    const formData = {
      name: value.name,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    const updateFormData = {
      id: Number(updateData?.id),
      name: value.name,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
    };
    if (updateData?.id) {
      // update holiday
      setIsLoading(true);
      const result = await updateHolyday({
        variables: updateFormData,
      });
      if (result?.data?.updateHolyday?.result_code == "000") {
        Swal.fire("Updated!", "Your holyday has been updated.", "success");
        setIsLoading(false);
        // redirect to holydays list
        history.push("/leave/holydays");
      }
      if (result?.data?.updateHolyday?.result_code == "002") {
        setIsLoading(false);
        Swal.fire(result?.data?.updateHolyday?.status, "", "error");
      }
    } else {
      // create holiday
      setIsLoading(true);
      const result = await createHolyday({
        variables: formData,
      });
      if (result?.data?.createHolyday?.result_code == "000") {
        setIsLoading(false);
        Swal.fire("Created!", "Your holyday has been created.", "success");
        // redirect to holydays list
        history.push("/leave/holydays");
      }
      if (result?.data?.createHolyday?.result_code == "002") {
        setIsLoading(false);
        Swal.fire(result?.data?.createHolyday?.status, "", "error");
      }
    }
  };

  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Col md={12}>
            <h3 className="page-title">New Holyday</h3>
          </Col>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={6} className="mx-auto">
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Start Date</label>
                    <div className="form__form-group-field">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        showWeekNumbers
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">End Date</label>
                    <div className="form__form-group-field">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setEndDate(date);
                        }}
                        showWeekNumbers
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Name</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="name"
                        component="input"
                        control={control}
                        type="text"
                        defaultValue={updateData?.name}
                        placeholder={`Enter holyday name`}
                      />
                    </div>
                    {isFormError ? (
                      <span className="form__form-group-error text-danger">
                        {isFormError?.message}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/leave/holydays")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      {updateData?.id ? (
                        updateHolydayStatus?.loading ? (
                          <Button
                            disabled={isLoading}
                            color="primary"
                            type="submit"
                          >
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Updating...
                          </Button>
                        ) : (
                          <Button
                            disabled={isLoading}
                            color="primary"
                            type="submit"
                          >
                            Update
                          </Button>
                        )
                      ) : createHolydayStatus?.loading ? (
                        <Button
                          disabled={isLoading}
                          color="primary"
                          type="submit"
                        >
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Creating...
                        </Button>
                      ) : (
                        <Button
                          disabled={isLoading}
                          color="primary"
                          type="submit"
                        >
                          Create
                        </Button>
                      )}
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default HolydayForm;
