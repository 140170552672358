import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import {
  DELETE_LEAVE_BY_ID,
  LEAVE_DELETED_STATUS_UPDATE,
} from "../../../GraphQL/Mutation";
import { useMutation, useQuery } from "@apollo/client";
import { successMessage, warningMessage } from "../../ReactToastify";
import { useCallback, useEffect, useState } from "react";
import {
  Table,
  Badge,
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  ModalFooter,
  ButtonToolbar,
  Col,
} from "reactstrap";
import {
  GET_ALL_ADDITIONAL_INFO,
  GET_ALL_LEAVES,
  GET_ALL_LEAVES_BY_USER_ID,
  GET_ALL_DEPARTMENT,
  GET_ALL_ADDITIONAL_INFO_BY_RFID,
  GET_USER_BY_USERID,
  GET_USER_BY_RFID,
} from "../../../GraphQL/Queries";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";
import { useSelector } from "react-redux";
import { Pagination } from "@mui/material";
import { loginInfo, usePagination } from "../../../utils/helpers";
import { decryption } from "../../HelperFunctions";
import ImageViewer from "react-simple-image-viewer";
import Swal from "sweetalert2";
import imageUrl from "@/utils/api/imageUrl";

const HistoryTable = () => {
  const history = useHistory();
  const [user, setuser] = useState({});
  const [email, setEmail] = useState({});

  const [modal, setModal] = useState(false);
  const [deleteToggle, setDeleteToggle] = useState(false);
  // deparment head of the department email
  const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);
  // it is user for 'allLeaves' state update
  const [anyChangeLeave, setAnyChangeLeave] = useState(false);
  const updateState = useSelector((state) => state?.leave?.updateLeave);

  // sorted function
  Array.prototype.sortBy = function (p) {
    return this.slice(0).sort(function (a, b) {
      return a[p] < b[p] ? 1 : a[p] > b[p] ? -1 : 0;
    });
  };

  const LoadAdditionalInfo = useQuery(GET_ALL_ADDITIONAL_INFO);

  const modalHandler = () => {
    setModal((prevSate) => !prevSate);
  };

  // get user id from local storage
  useEffect(() => {
    const token = localStorage?.getItem("token");
    jwt.verify(token, "test", function (err, decoded) {
      if (err) {
        localStorage.removeItem("token");
      }
      setuser(decoded);
    });
  }, []);

  const leaveTypeList = [
    {
      value: 1,
      label: "Casual",
    },
    {
      value: 2,
      label: "Anual",
    },
    {
      value: 3,
      label: "Sick",
    },
    {
      value: 4,
      label: "Menstrual",
    },
    {
      value: 5,
      label: "Maternity",
    },
    {
      value: 6,
      label: `Reimburse`,
    },
  ];

  const [deleteState, setDeleteState] = useState(0);
  const LoadAllLeaves = useQuery(GET_ALL_LEAVES_BY_USER_ID, {
    skip: !user?.id,
    variables: {
      user_id: user?.id,
    },
  });

  const [allLeaves, setAllLeaves] = useState([]);
  useEffect(() => {
    setAllLeaves(
      LoadAllLeaves?.data?.getAllLeavesByUserId?.sortBy("createdAt")
    );
    LoadAllLeaves?.refetch();
  }, [
    LoadAllLeaves?.data?.getAllLeavesByUserId?.length,
    LoadAllLeaves?.data?.getAllLeavesByUserId,
    deleteToggle,
    updateState,
  ]);

  // delete leave by id
  const [deleteLeaveById] = useMutation(LEAVE_DELETED_STATUS_UPDATE);

  const deleteHandler = async (deleteId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const result = await deleteLeaveById({
          variables: {
            id: Number(deleteId),
          },
        });
        if (result?.data?.leaveDeletedStatusUpdate?.result_code == "000") {
          setDeleteToggle((prevState) => !prevState);
          Swal.fire(
            "Deleted!",
            "Your leave application has been deleted.",
            "success"
          );
        }
        if (result?.data?.leaveDeletedStatusUpdate?.result_code == "002") {
          Swal.fire(
            "Deleted!",
            result?.data?.leaveDeletedStatusUpdate?.status,
            "success"
          );
        }
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Your imaginary file is safe :)", "error");
      }
    });
  };
  const userid = email?.rfid;
  const {
    data: userDetailsData,
    loading,
    error,
    refetch,
  } = useQuery(GET_USER_BY_RFID, {
    variables: {
      rfid: userid,
    },
  });

  // console.log(
  //   "🚀 ~ HistoryTable ~ userDetailsData:",
  //   email?.user?.additionalInfo?.user_id
  // );

  // material pagination
  let [page, setPage] = useState(1);
  const PER_PAGE = 10;
  const count = Math.ceil(allLeaves?.length / PER_PAGE);
  const pageData = usePagination(allLeaves, PER_PAGE);
  const handleChange = (e, p) => {
    setPage(p);
    pageData.jump(p);
  };
  // image viewer
  // const loggedInfo = loginInfo();
  const [attachment, setAttachment] = useState({});
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [reliverId, setReliverId] = useState(null);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const LoadAdditionalInfoByRfid = useQuery(GET_ALL_ADDITIONAL_INFO_BY_RFID, {
    skip: !reliverId,
    variables: {
      rfid: reliverId,
    },
  });

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  let url = [];

  if (Object.keys(attachment)?.length === 0) {
    null;
  } else {
    JSON.parse(attachment?.images)?.map((image) => {
      url.push(image?.image);
    });
  }
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );
  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
  ];
  // weekend check for attendance
  const IsThereWeekend = (startDate, endDate) => {
    // date format: new Date("2022-03-01");
    let weekendDates = [];
    const initialState = startDate;
    initialState.setDate(startDate.getDate());
    for (
      let date = initialState;
      date <= endDate;
      date.setDate(date.getDate() + 1)
    ) {
      if (date.getDay() < 5) {
        weekendDates.push(new Date(date.toString()));
      }
    }
    return weekendDates;
  };

  return (
    <Col>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th style={{ width: "60px" }}>#</th>
            <th>Start</th>
            <th>End</th>
            <th>Total</th>
            <th>Status</th>
            <th>Applied</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pageData?.currentData()?.length ? (
            pageData?.currentData()?.map((item, index) => (
              <tr onClick={() => setReliverId(item?.rfid)} key={item?.id}>
                <th
                  style={{ width: "60px" }}
                  scope="row"
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {index + 1}
                </th>
                <td
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {moment(item?.start_date).format("LL")}
                </td>
                <td
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {moment(item?.end_date).format("LL")}
                </td>
                <td
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {/* {moment
                    .duration(
                      new Date(item?.end_date) - new Date(item?.start_date)
                    )
                    .asDays() + 1}{" "}
                  {moment
                    .duration(
                      new Date(item?.end_date) - new Date(item?.start_date)
                    )
                    .asDays() +
                    1 >
                  1
                    ? "Days"
                    : "Day"} */}
                  {
                    IsThereWeekend(
                      new Date(item?.start_date),
                      new Date(item?.end_date)
                    )?.length
                  }{" "}
                  {IsThereWeekend(
                    new Date(item?.start_date),
                    new Date(item?.end_date)
                  )?.length > 1
                    ? "Days"
                    : "Day"}
                </td>

                <td
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {item?.leave_status == "Pending" ||
                  item?.leave_status == "Denied" ? (
                    <Badge color="danger">{item?.leave_status}</Badge>
                  ) : (
                    <Badge color="success">{item?.leave_status}</Badge>
                  )}
                </td>
                <td
                  onClick={() => {
                    modalHandler();
                    setEmail(item);
                    setAttachment(item);
                  }}
                >
                  {moment(new Date(Number(item?.createdAt))).fromNow()}
                </td>
                <td>
                  {permissions?.includes("delete_leave")
                    ? item?.leave_status == "Pending" && (
                        <button
                          className="inbox__email-btn"
                          type="button"
                          aria-label="edit"
                          onClick={() => {
                            deleteHandler(item?.id);
                            setDeleteState(item?.id);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </button>
                      )
                    : null}
                  {permissions?.includes("udpate_leave")
                    ? item?.leave_status == "Pending" && (
                        <button
                          className="inbox__email-btn mr-1"
                          type="button"
                          aria-label="edit"
                          onClick={() =>
                            history.push({
                              pathname:
                                item?.leave_type == "101" ||
                                item?.leave_type == "102"
                                  ? "/leave/wfh-od"
                                  : "/leave/new",
                              state: {
                                leave: item,
                                LoadAdditionalInfo:
                                  LoadAdditionalInfo?.data
                                    ?.getAllAdditionalInfo,
                              },
                            })
                          }
                        >
                          <EditIcon fontSize="small" />
                        </button>
                      )
                    : null}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="9" className="text-center text-success">
                <b>You have not any leave application</b>
              </td>
            </tr>
          )}
        </tbody>

        {/* modal for leave view in details */}
        <Modal toggle={modalHandler} isOpen={modal}>
          <ModalHeader toggle={modalHandler}>
            <div className="inbox__email-left">
              <div className="inbox__email-ava mr-3">
                <img
                  src={
                    email?.user?.images
                      ? email?.user?.images
                      : "https://www.kindpng.com/picc/m/24-248253_user-profile-default-image-png-clipart-png-download.png"
                  }
                  alt=""
                />
              </div>
              <div className="inbox__email-info">
                <h5 className="inbox__email-name" style={{ fontSize: 20 }}>
                  {email?.user?.name} ({email?.user?.additionalInfo?.office_id})
                </h5>
                <p className="inbox__email-email mt-1" style={{ fontSize: 14 }}>
                  {email?.user?.email}
                </p>
              </div>
            </div>
          </ModalHeader>
          {console.log(email)}
          <ModalBody>
            <p className="mb-1">
              {moment(new Date(Number(email?.createdAt))).format(
                "dddd, Do MMMM YYYY, h:mm:ss a"
              )}
            </p>
            <div className="flex justify-content-between">
              <h4>
                Application for{" "}
                {
                  leaveTypeList?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }
                {
                  attendanceTypeArray?.find(
                    (item) => item?.value == email?.leave_type
                  )?.label
                }
                {leaveTypeList?.find((item) => item?.value == email?.leave_type)
                  ?.label
                  ? " leave"
                  : null}
              </h4>
              <div>
                {email?.leave_status == "Pending" ||
                email?.leave_status == "Denied" ? (
                  <Badge color="danger">{email?.leave_status}</Badge>
                ) : (
                  <Badge color="success">{email?.leave_status}</Badge>
                )}
              </div>
            </div>
            <p>
              <b>Start: </b>
              {moment(email?.start_date).format("DD MMMM YYYY")}
            </p>
            <p>
              <b>End: </b>
              {moment(email?.end_date).format("DD MMMM YYYY")}
            </p>
            <p>
              <b>Total: </b>
              {
                IsThereWeekend(
                  new Date(email?.start_date),
                  new Date(email?.end_date)
                )?.length
              }{" "}
              {IsThereWeekend(
                new Date(email?.start_date),
                new Date(email?.end_date)
              )?.length > 1
                ? "Days"
                : "Day"}
            </p>
            <p className="mt-3">
              <b>
                Reason: <br />
              </b>{" "}
              {email?.leave_reason}
            </p>
            {LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
              ?.user && (
              <p className="mt-3">
                <b>
                  Reliever: <br />
                </b>
                Name:{" "}
                {
                  LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                    ?.user?.name
                }{" "}
                (
                {
                  LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                    ?.rfid
                }
                ) <br />
                Email:{" "}
                {
                  LoadAdditionalInfoByRfid?.data?.getAllAdditionalInfoByRfid[0]
                    ?.user?.email
                }
              </p>
            )}
            {Object.keys(attachment)?.length === 0 ? null : JSON.parse(
                email?.images
              )?.length > 0 ? (
              <div>
                <p>
                  <b>Attachment ({JSON.parse(email?.images)?.length})</b>
                </p>
                {url.map((src, index) => (
                  <a
                    href={`${imageUrl}/leaves/${src}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={`${imageUrl}/leaves/${src}`}
                      // onClick={() => openImageViewer(index)}
                      key={index}
                      style={{
                        margin: "2px",
                        height: "50px",
                        width: "auto",
                        border: "1px solid #ddd",
                        borderRadius: "5px",
                      }}
                      alt="image"
                    />
                  </a>
                ))}
              </div>
            ) : null}
            {email?.deny_reason ? (
              <div className="mt-3 bg-danger text-white rounded px-3 py-2">
                <h4>The application has been denied!</h4>
                <p>
                  By {""}
                  {
                    userDetailsData?.getUserByRfid?.name
                    // LoadAllDepartment?.data?.getAllDepartments?.find(
                    //   (item) => item?.id == email?.user?.additionalInfo?.dept_id
                    // )?.user?.name
                  }{" "}
                  (
                  {
                    userDetailsData?.getUserByRfid?.username
                    // LoadAllDepartment?.data?.getAllDepartments?.find(
                    //   (item) => item?.id == email?.user?.additionalInfo?.dept_id
                    // )?.user?.username
                  }
                  ) at{" "}
                  {moment(new Date(Number(email?.updatedAt))).format(
                    "dddd, Do MMMM YYYY, h:mm:ss a"
                  )}
                </p>
                <p>
                  <b>Reason</b> <br /> {email?.deny_reason}
                </p>
              </div>
            ) : null}
            {email?.leave_status == "Approved" ? (
              <div className="mt-3 bg-success text-white rounded px-3 py-2">
                <h4>The application has been approved!</h4>
                <p>
                  By{" "}
                  {
                    userDetailsData?.getUserByRfid?.name
                    // LoadAllDepartment?.data?.getAllDepartments?.find(
                    //   (item) => item?.id == email?.user?.additionalInfo?.dept_id
                    // )?.user?.name
                  }{" "}
                  (
                  {
                    userDetailsData?.getUserByRfid?.username
                    // LoadAllDepartment?.data?.getAllDepartments?.find(
                    //   (item) => item?.id == email?.user?.additionalInfo?.dept_id
                    // )?.user?.username
                  }
                  ) at{" "}
                  {moment(new Date(Number(email?.updatedAt))).format(
                    "dddd, Do MMMM YYYY, h:mm:ss a"
                  )}
                </p>
              </div>
            ) : null}
            {isViewerOpen && (
              <ImageViewer
                src={`${imageUrl}/leaves/${src}`}
                currentIndex={currentImage}
                onClose={closeImageViewer}
                disableScroll={false}
                backgroundStyle={{
                  backgroundColor: "rgba(0,0,0,0.9)",
                }}
                closeOnClickOutside={true}
              />
            )}
          </ModalBody>
        </Modal>
      </Table>

      <Pagination count={count} page={page} onChange={handleChange} />
    </Col>
  );
};

export default HistoryTable;
