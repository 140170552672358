import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Company, NewCompany } from "../../../Company";
import { decryption } from "../../../HelperFunctions";
import Notice from "../../../Company/components/Notice/Notice";
import NewNotice from "../../../Company/components/Notice/NewNotice";

export default () => {
  const permission = JSON.parse(localStorage?.getItem("permissions"));

  return (
    <Switch>
      <Route
        path="/notice/list"
        render={() =>
          decryption(permission)?.includes("view_department") ? (
            <Notice></Notice>
          ) : (
            <Redirect to="/404" />
          )
        }
      />
      <Route
        path="/notice/new"
        render={() =>
          decryption(permission)?.includes("view_department") ? (
            <NewNotice></NewNotice>
          ) : (
            <Redirect to="/404" />
          )
        }
      />
    </Switch>
  );
};
