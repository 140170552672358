// import { useMutation, useQuery } from "@apollo/client";
// import React, { useState } from "react";
// import {
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Row,
// } from "reactstrap";
// import { UPDATE_USER } from "../../GraphQL/Mutation";
// import { GET_ALL_ROLE, GET_USER_BY_USERID } from "../../GraphQL/Queries";
// import Swal from "sweetalert2";

// const AssignRoleEdit = (props) => {
//   const getAllRole = useQuery(GET_ALL_ROLE);
//   const userId = props?.user?.id;
//   const {
//     data: userDetailsData,
//     loading,
//     error,
//     refetch,
//   } = useQuery(GET_USER_BY_USERID, {
//     skip: !userId,
//     variables: { id: userId },
//     onCompleted: (data) => {
//       if (data?.getUserByUserid?.role) {
//         const roles = data.getUserByUserid.role.map((role) => ({
//           value: role.id,
//           label: role.role_name,
//         }));
//         setExistingRole(roles); // Set default roles once data is fetched
//       }
//     },
//   });

//   refetch();
//   const [userUpdate, userData] = useMutation(UPDATE_USER);

//   const defaultRole = userDetailsData?.getUserByUserid?.role?.map((role) => {
//     return {
//       value: role.id,
//       label: role.role_name,
//     };
//   });
//   const [existingRole, setExistingRole] = useState(
//     defaultRole ? defaultRole : []
//   );

//   const checkRole = (value) => {
//     const check = existingRole?.find((role) => role.value == value.id);
//     if (check) {
//       const list = [...existingRole];
//       const index = list.indexOf((role) => role.value == value.id);
//       list.splice(index, 1);
//       setExistingRole(list);
//     } else {
//       setExistingRole([
//         ...existingRole,
//         { value: value.id, label: value.role_name },
//       ]);
//     }
//   };
//   const handleSubmit = async () => {
//     await userUpdate({
//       variables: {
//         ...props.user,
//         role: existingRole?.map((data) => {
//           return { id: data.value };
//         }),
//       },
//     });
//     Swal.fire({
//       title: "Role!",
//       text: "User Role has been update.",
//       icon: "success",
//     });

//     await props.refetch();
//     props.toggle();
//   };

//   return (
//     <>
//       <ModalHeader>
//         <h2>Edit Assigned Role</h2>
//       </ModalHeader>
//       <ModalBody>
//         <Form>
//           <FormGroup as={Row} controlId="formPlaintextEmail">
//             <Label column sm="4">
//               User
//             </Label>
//             <Col sm="6">{props.user.name}</Col>
//           </FormGroup>

//           <FormGroup as={Row} controlId="formPlaintextPassword">
//             <Label column sm="4">
//               Select Role
//             </Label>
//             <Col sm="6">
//               {getAllRole?.data?.getRoles?.map((item) => (
//                 <>
//                   <label>
//                     <input
//                       type="checkbox"
//                       value={item.role_name}
//                       checked={
//                         existingRole?.find(
//                           (value) => value?.label === item?.role_name
//                         )
//                           ? true
//                           : false
//                       }
//                       onChange={(e) => checkRole(item)}
//                     />{" "}
//                     {item.role_name}
//                   </label>{" "}
//                   <br />
//                 </>
//               ))}
//             </Col>
//           </FormGroup>
//         </Form>
//       </ModalBody>
//       <ModalFooter>
//         <button className="btn btn-danger" onClick={props.toggle}>
//           Cancel
//         </button>
//         <button className="btn btn-success" onClick={handleSubmit}>
//           Update
//         </button>
//       </ModalFooter>
//     </>
//   );
// };

// export default AssignRoleEdit;

import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { UPDATE_USER } from "../../GraphQL/Mutation";
import { GET_ALL_ROLE, GET_USER_BY_USERID } from "../../GraphQL/Queries";

const AssignRoleEdit = (props) => {
  const getAllRole = useQuery(GET_ALL_ROLE);
  const userId = props?.user?.id;

  const {
    data: userDetailsData,
    loading,
    error,
    refetch,
  } = useQuery(GET_USER_BY_USERID, {
    skip: !userId,
    variables: { id: userId },
    onCompleted: (data) => {
      if (data?.getUserByUserid?.role) {
        const roles = data.getUserByUserid.role.map((role) => ({
          value: role.id,
          label: role.role_name,
        }));
        setExistingRole(roles); // Set default roles once data is fetched
      }
    },
  });

  const [userUpdate] = useMutation(UPDATE_USER);

  const [existingRole, setExistingRole] = useState([]);

  const checkRole = (value) => {
    const check = existingRole?.find((role) => role.value === value.id);
    if (check) {
      const updatedRoles = existingRole.filter(
        (role) => role.value !== value.id
      );
      setExistingRole(updatedRoles);
    } else {
      setExistingRole([
        ...existingRole,
        { value: value.id, label: value.role_name },
      ]);
    }
  };

  const handleSubmit = async () => {
    await userUpdate({
      variables: {
        ...props.user,
        role: existingRole?.map((data) => ({ id: data.value })),
      },
    });
    await props.refetch();
    props.toggle();
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <>
      <ModalHeader>
        <h2>Edit Assigned Role</h2>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row} controlId="formPlaintextEmail">
            <Label column sm="4">
              User
            </Label>
            <Col sm="6">{props.user.name}</Col>
          </FormGroup>

          <FormGroup as={Row} controlId="formPlaintextPassword">
            <Label column sm="4">
              Select Role
            </Label>
            <Col sm="6">
              {getAllRole?.data?.getRoles?.map((item) => (
                <div key={item.id}>
                  <label>
                    <input
                      type="checkbox"
                      value={item.role_name}
                      checked={
                        !!existingRole.find(
                          (role) => role.label === item.role_name
                        )
                      }
                      onChange={() => checkRole(item)}
                    />
                    <span style={{ marginLeft: "8px" }}>{item.role_name}</span>{" "}
                    {/* {item.role_name} */}
                  </label>
                  <br />
                </div>
              ))}
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-danger" onClick={props.toggle}>
          Cancel
        </button>
        <button className="btn btn-success" onClick={handleSubmit}>
          Update
        </button>
      </ModalFooter>
    </>
  );
};

export default AssignRoleEdit;
