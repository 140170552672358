// import { useMutation } from "@apollo/client";
// import React, { useState } from "react";
// import {
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Row,
// } from "reactstrap";
// import { CREATE_PERMISSIONS } from "../../GraphQL/Mutation";

// const AddPermission = ({ toggle, refetch }) => {
//   const [permissionValue, setPermissionValue] = useState("");
//   const [createPermission, permissionData] = useMutation(CREATE_PERMISSIONS);
//   const handlePermissions = async () => {
//     await createPermission({
//       variables: {
//         permission_name: permissionValue,
//       },
//     });
//     setPermissionValue("");
//     await refetch();
//     toggle();
//   };

//   return (
//     <>
//       <ModalHeader>
//         <h1>New Permission</h1>
//       </ModalHeader>
//       <ModalBody>
//         <Form>
//           <FormGroup as={Row} controlId="formPlaintextEmail">
//             <Label column sm="4">
//               Enter Permission
//             </Label>
//             <Col sm="6">
//               <input
//                 className="form-control"
//                 value={permissionValue}
//                 onChange={(e) => setPermissionValue(e.target.value)}
//                 placeholder="Enter Permission Name"
//               />
//             </Col>
//           </FormGroup>
//         </Form>
//       </ModalBody>
//       <ModalFooter>
//         <button className="btn btn-danger" onClick={toggle}>
//           Cancel
//         </button>
//         <button className="btn btn-success" onClick={handlePermissions}>
//           Save
//         </button>
//       </ModalFooter>
//     </>
//   );
// };

// export default AddPermission;
import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { CREATE_PERMISSIONS } from "../../GraphQL/Mutation";

const AddPermission = ({ toggle, refetch }) => {
  const [permissionValue, setPermissionValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [createPermission] = useMutation(CREATE_PERMISSIONS, {
    onError: (error) => {
      setLoading(false);
      console.error("Error creating permission:", error.message);
      // Optionally, show a user-friendly error message here
    },
    onCompleted: () => {
      setLoading(false);
      setPermissionValue(""); // Clear input field on success
      refetch(); // Refetch after permission is created
      toggle(); // Close the modal after success
    },
  });

  const handlePermissions = async () => {
    if (!permissionValue.trim()) {
      // Optional validation to check if permission name is not empty
      alert("Permission name is required");
      return;
    }

    setLoading(true);

    try {
      // Send create permission mutation
      await createPermission({
        variables: {
          permission_name: permissionValue,
        },
      });
    } catch (error) {
      console.error("Permission creation failed:", error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <ModalHeader>
        <h1>Create Permission</h1>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="permissionName">Permission Name</Label>
            <input
              id="permissionName"
              type="text"
              className="form-control"
              value={permissionValue}
              onChange={(e) => setPermissionValue(e.target.value)}
              placeholder="Enter permission name"
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="btn btn-danger"
          onClick={toggle}
          disabled={loading} // Disable button when loading
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handlePermissions}
          disabled={loading || !permissionValue.trim()} // Disable button if permission name is empty
        >
          {loading ? <Spinner size="sm" /> : "Save"}
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddPermission;
