/* eslint-disable comma-dangle */
/* eslint-disable react/prop-types */
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import React from "react";
import { useHistory } from "react-router";
import { Card, CardBody, Col } from "reactstrap";
import AddressInfo from "./AddressInfo";

const ProfileMain = ({ farmer }) => {
  const history = useHistory();
  const findFistDigit = farmer?.mobile?.length && farmer?.mobile[0];
  const replaceDigit = farmer?.mobile?.replace(findFistDigit, "(+880)");
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="image-container">
            <div className="profile__avatar image-shadow">
              <img src={farmer.image} alt="avatar" className="rounded-circle" />
            </div>
          </div>
          <div className="profile__information">
            <div className="profile__data">
              <h3 className="bold-text">{farmer.name}</h3>
              <h5 className="mt-2">SFID: {farmer.farmerId}</h5>
              <h4 className="mt-1">
                <PhoneInTalkIcon fontSize="small" /> {farmer?.mobile}
              </h4>
            </div>
          </div>

          <div className="m-4">
            <AddressInfo farmer={farmer} />
          </div>

          <h4 className="m-4 bold-text">
            <FamilyRestroomIcon fontSize="small" /> Family Information
          </h4>
          <div className="profile__stats">
            <div className="profile__stat">
              <p className="profile__stat-number">{farmer.male_members}</p>
              <p className="profile__stat-title">Male Member</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{farmer.female_members}</p>
              <p className="profile__stat-title">Female Member</p>
            </div>
            <div className="profile__stat">
              <p className="profile__stat-number">{farmer.school_going}</p>
              <p className="profile__stat-title">School Going</p>
            </div>
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default ProfileMain;
