import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { UPDATE_ROLES } from "../../GraphQL/Mutation";
import { GET_ALL_PERMISSIONS } from "../../GraphQL/Queries";

const EditRole = (props) => {
  const getAllPermission = useQuery(GET_ALL_PERMISSIONS);

  const [updateRole, updateData] = useMutation(UPDATE_ROLES);
  const [roleData, setRoleData] = useState({
    id: "",
    name: "",
    permissions: [],
  });

  const changeRole = (e) => {
    // setRole(e.target.value);
  };
  const existPermissions = props?.role?.permissions?.map((role) => {
    return {
      permission_name: role.permission_name,
    };
  });
  const [permissions, setPermissions] = useState(existPermissions);
  const { role, onCloseEdit } = props;
  const [isLoading, setIsLoading] = useState(false);
  const submitRole = async () => {
    setIsLoading(true);
    const roleData = {
      id: role.id,
      role_name: role.role_name,
      permissions,
    };

    await updateRole({
      variables: roleData,
    });
    await props.refetch();
    props.toggle();
  };

  const checkPermission = (e, index) => {
    let check = permissions?.find(
      (data) => data?.permission_name == e.target.value
    );
    if (check) {
      const list = [...permissions];
      const index = permissions?.findIndex(
        (data) => data?.permission_name == e.target.value
      );
      list.splice(index, 1);
      setPermissions(list);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setPermissions([...permissions, { permission_name: e.target.value }]);
    }
  };

  return (
    <>
      <ModalHeader>
        <h2>Edit Role</h2>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row} controlId="role">
            <Label className="pl-0" column sm="4">
              <b>Enter Role</b>
            </Label>
            <Col className="pl-0" sm="6">
              <input
                className="form-control"
                value={props.role.role_name}
                onChange={changeRole}
                placeholder="Enter Role Name"
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row} controlId="permissions">
            <Label className="pl-0 mb-2" column sm="4">
              <b>Permissions</b>
            </Label>
            <Row className="flex-wrap wrap">
              {getAllPermission.data?.getPermissions?.map((item, index) => (
                <Col md={4} lg={3}>
                  <label>
                    <input
                      type="checkbox"
                      value={item.permission_name}
                      checked={
                        permissions?.find(
                          (value) =>
                            value?.permission_name === item?.permission_name
                        )
                          ? true
                          : false
                      }
                      onChange={(e) => checkPermission(e, index)}
                    />{" "}
                    {item.permission_name}
                  </label>{" "}
                  <br />
                </Col>
              ))}
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <button onClick={onCloseEdit} className="btn btn-danger">
          Cancel
        </button>
        <button
          disabled={isLoading}
          onClick={submitRole}
          className="btn btn-success"
        >
          Update
        </button>
      </ModalFooter>
    </>
  );
};

export default EditRole;
