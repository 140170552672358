import React, { useState } from "react";
import { Button, Col, Modal, Row, Table } from "reactstrap";
import moment from "moment";
import { useQuery } from "@apollo/client";
import imageUrl from "@/utils/api/imageUrl";
import {
  GET_ALL_COMPANIES,
  GET_USER_BY_USERID,
} from "../../../../GraphQL/Queries";
import SkeletonLoader from "./SkeletonLoader";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf"; // Import PDF icon
import ImageIcon from "@mui/icons-material/Image";
const UserDetailsModal = ({ isOpen, toggle, userId }) => {
  const {
    data: userDetailsData,
    loading,
    error,
    refetch,
  } = useQuery(GET_USER_BY_USERID, {
    skip: !userId,
    variables: {
      id: userId,
    },
  });
  refetch();
  const documents = userDetailsData?.getUserByUserid?.documents || [];

  // const renderDocument = (fileName) => {
  //   const fileExtension = fileName.split(".").pop().toLowerCase();

  //   if (["jpg", "jpeg", "png"].includes(fileExtension)) {
  //     // For image files, display the image directly
  //     return (
  //       <img
  //         src={`${imageUrl}/documents/${fileName}`}
  //         alt={fileName}
  //         style={{
  //           width: "150px",
  //           height: "150px",
  //           objectFit: "cover",
  //           marginRight: "10px",
  //         }}
  //       />
  //     );
  //   } else if (fileExtension === "pdf") {
  //     // For PDF files, embed the PDF directly
  //     return (
  //       <embed
  //         src={`${imageUrl}/documents/${fileName}`}
  //         type="application/pdf"
  //         width="150px"
  //         height="150px"
  //         style={{ marginRight: "10px" }}
  //       />
  //     );
  //   } else {
  //     // Unsupported file type
  //     return <span>Unsupported file format</span>;
  //   }
  // };
  const renderDocument = (fileName) => {
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (["jpg", "jpeg", "png"].includes(fileExtension)) {
      // Render image thumbnail
      return (
        <a
          href={`${imageUrl}/documents/${fileName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={`${imageUrl}/documents/${fileName}`}
            alt={fileName}
            style={{
              width: "150px",
              height: "150px",
              objectFit: "cover",
            }}
          />
        </a>
      );
    } else if (fileExtension === "pdf") {
      // Render PDF with click-to-open behavior
      return (
        <div
          onClick={() =>
            window.open(`${imageUrl}/documents/${fileName}`, "_blank")
          }
          style={{
            display: "inline-block",
            cursor: "pointer",
          }}
        >
          <embed
            src={`${imageUrl}/documents/${fileName}`}
            type="application/pdf"
            width="150px"
            height="150px"
            style={{
              marginRight: "10px",
              pointerEvents: "none",
            }}
          />
        </div>
      );
    } else {
      // Unsupported file type
      return <span>Unsupported file format</span>;
    }
  };
  const [hover, setHover] = useState(false);
  const { data } = useQuery(GET_ALL_COMPANIES);
  const allCompanies = data?.getAllCompanies || [];

  const userCompanyId =
    userDetailsData?.getUserByUserid?.id?.companyCollaborationInfo?.[0]
      ?.company_id;

  const company = allCompanies.find((item) => item?.value === userCompanyId);

  return (
    <Modal toggle={toggle} isOpen={isOpen} centered>
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div className="employee-profile">
          <div className="d-flex justify-content-end mt-3">
            <button
              style={{
                color: hover ? "white" : "red",
                backgroundColor: hover ? "red" : "white",
                border: "1px solid red",
                padding: "4px 8px",
                fontSize: "14px",
                borderRadius: "4px",
                cursor: "pointer",
              }}
              onMouseEnter={() => setHover(true)} // Hover in
              onMouseLeave={() => setHover(false)} // Hover out
              onClick={toggle}
            >
              X
            </button>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="profile-img">
                <img
                  className="img-fluid"
                  src={
                    userDetailsData?.getUserByUserid?.images ||
                    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"
                  }
                  alt="Profile"
                  onError={(e) => {
                    e.target.onerror = null; // Prevents looping if the fallback image also fails
                    e.target.src =
                      "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";
                  }}
                />
              </div>
              <div className="profile-head mt-3">
                <h4>{userDetailsData?.getUserByUserid?.name}</h4>
                <h5>
                  {
                    userDetailsData?.getUserByUserid?.additionalInfo
                      ?.designationInfo?.name
                  }{" "}
                  <br />
                  {
                    userDetailsData?.getUserByUserid?.additionalInfo
                      ?.departmentInfo?.name
                  }
                </h5>
              </div>
              <div className="profile-work">
                <p>ROLES</p>
                {userDetailsData?.getUserByUserid?.role?.map((role, index) => (
                  <span key={index} className="profile-rating">
                    {index + 1}. {role?.role_name} <br />
                  </span>
                ))}

                <p>LEAVE LEFT</p>
                {userDetailsData?.getUserByUserid?.gender === "Male" ? (
                  <div>
                    <span className="profile-rating">
                      Casual:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.casual}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Sick:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.sick}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Annual:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.anual}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Reimburse:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.reward}
                    </span>{" "}
                    <br />
                  </div>
                ) : userDetailsData?.getUserByUserid?.gender === "Female" ? (
                  <div>
                    <span className="profile-rating">
                      Casual:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.casual}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Sick:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.sick}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Annual:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.anual}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Reimburse:{" "}
                      {userDetailsData?.getUserByUserid?.additionalInfo?.reward}
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Maternity:{" "}
                      {
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.maternity
                      }
                    </span>{" "}
                    <br />
                    <span className="profile-rating">
                      Menstrual:{" "}
                      {
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.menstrual
                      }
                    </span>{" "}
                    <br />
                  </div>
                ) : null}
              </div>
            </div>
            <div className="col-md-8">
              <h4 className="border-bottom mb-3 mt-4 pb-2">About</h4>
              <Table borderless hover responsive size="sm" striped>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{userDetailsData?.getUserByUserid?.name}</td>
                  </tr>
                  <tr>
                    <td>User Name</td>
                    <td>{userDetailsData?.getUserByUserid?.username}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{userDetailsData?.getUserByUserid?.gender}</td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td>{userDetailsData?.getUserByUserid?.phone}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>{userDetailsData?.getUserByUserid?.email}</td>
                  </tr>
                  <tr>
                    <td>RFID</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.additionalInfo?.rfid}
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h4 className="border-bottom mb-3 mt-4 pb-2">Address</h4>
              <Table borderless hover responsive size="sm" striped>
                <tbody>
                  <tr>
                    <td>Division</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.divisionInfo?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>District</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.districtInfo?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Upazilla</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.upazillaInfo?.name}
                    </td>
                  </tr>
                  <tr>
                    <td>Union</td>
                    <td>{userDetailsData?.getUserByUserid?.unionInfo?.name}</td>
                  </tr>
                  <tr>
                    <td>Village</td>
                    <td>{userDetailsData?.getUserByUserid?.village}</td>
                  </tr>
                </tbody>
              </Table>
              <h4 className="border-bottom mb-3 mt-4 pb-2">
                Additional Section
              </h4>
              <Table borderless hover responsive size="sm" striped>
                <tbody>
                  <tr>
                    <td>Company</td>
                    <td>
                      {
                        userDetailsData?.getUserByUserid
                          ?.companyCollaborationInfo[0]
                          ?.companyCollaborationCompanyInfo?.name
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Office Id</td>
                    <td>
                      {
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.office_id
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Joining Date</td>
                    <td>
                      {moment(
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.joining_date
                      ).format("DD-MM-YYYY")}{" "}
                      (
                      {moment(
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.joining_date
                      ).fromNow("yy")}
                      )
                    </td>
                  </tr>
                  <tr>
                    <td>Job Type</td>
                    <td>{userDetailsData?.getUserByUserid?.jobType}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>
                      {/* {
                        userDetailsData?.getUserByUserid?.additionalInfo
                          ?.description
                      } */}
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            userDetailsData?.getUserByUserid?.additionalInfo
                              ?.description,
                        }}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Employee Status</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.isActive
                        ? "Active"
                        : "Inactive"}
                    </td>
                  </tr>
                  <tr>
                    <td>Employee Classification</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.EmployeeClassification}
                    </td>
                  </tr>
                  <tr>
                    <td>Working Time</td>
                    <td>
                      {userDetailsData?.getUserByUserid?.workingTime} Hours
                    </td>
                  </tr>
                  <tr>
                    <td style={{ paddingRight: "10px" }}>
                      Qualification/Specialization
                    </td>
                    <td className="mr-2">
                      {userDetailsData?.getUserByUserid?.qualification}
                    </td>
                  </tr>

                  {/* <tr>
                    <td>Employee Documents</td>
                    <td>
                      {documents.length > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: "10px",
                          }}
                        >
                          {documents.map((fileName, index) => (
                            <div key={index}>{renderDocument(fileName)}</div>
                          ))}
                        </div>
                      ) : (
                        <span>No documents available</span>
                      )}
                    </td>
                  </tr> */}
                </tbody>
              </Table>
              <div>
                <div>Employee Documents</div>
                <div>
                  {documents.length > 0 ? (
                    <Row>
                      {documents.map((fileName, index) => (
                        <Col key={index} md={6} lg={4}>
                          <div>
                            <a
                              href={`${imageUrl}/documents/${fileName}`} // Open file in a new tab
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                textDecoration: "none",
                                cursor: "pointer",
                              }}
                            >
                              {renderDocument(fileName)}
                            </a>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <span>No documents available</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default UserDetailsModal;
