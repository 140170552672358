import React from "react";
import { Card, CardBody, Col, Button, Row } from "reactstrap";
import { useHistory } from "react-router";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_LEAVES,
  GET_ALL_LEAVES_BETWEEN_DATES,
  GET_ALL_USER,
  SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID,
} from "../../../GraphQL/Queries";
import { useEffect, useState } from "react";
import ReportsTable from "./ReportsTable";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "./FormField";
import moment from "moment";
import AttendanceTable from "../../Attendance/components/AttendanceTable";
import { decryption } from "../../HelperFunctions";
import ReactExport from "react-export-excel";
import { MdOutlineDownloading } from "react-icons/md";
import jsPDF from "jspdf";
import "jspdf-autotable";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";

const doc = new jsPDF();

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Reports = () => {
  const history = useHistory();
  const [isDisabled, setIsDisabled] = useState(true);
  const updateLeaveData = history?.location?.state?.leave;
  // queries
  const searchAttendance = useQuery(SEARCH_ATTENDANCE_BETWEEN_DATE_AND_RFID, {
    skip: true,
  });
  const getAllLeavesBetweenDates = useQuery(GET_ALL_LEAVES_BETWEEN_DATES, {
    skip: true,
  });

  const LoadAllUsers = useQuery(GET_ALL_USER);
  const LoadAllLeaves = useQuery(GET_ALL_LEAVES);
  const [allLeaves, setAllLeaves] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [tableDisplayType, setTableDisplayType] = useState(null);
  // get previous date of month fron now start

  const prevDateOfMonth = new Date();
  const month = prevDateOfMonth.getMonth();
  prevDateOfMonth.setMonth(prevDateOfMonth.getMonth() - 1);
  while (prevDateOfMonth.getMonth() === month) {
    prevDateOfMonth.setDate(prevDateOfMonth.getDate() - 1);
  }
  // get previous date of month fron now close
  const [startDate, setStartDate] = useState(prevDateOfMonth);
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setAllLeaves(LoadAllLeaves?.data?.getAllLeaves);
    LoadAllLeaves?.refetch();
  }, [LoadAllLeaves?.data?.getAllLeaves?.length]);

  // setAllUsers
  useEffect(() => {
    setAllUsers(LoadAllUsers?.data?.getUser);
    LoadAllUsers?.refetch();
  }, [LoadAllUsers?.data?.getUser?.length]);

  // roles
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));

  // duplicate user removed
  const ids = allLeaves?.map((o) => o.user_id);
  const filteredUser = allLeaves?.filter(
    ({ user_id }, index) => !ids.includes(user_id, index + 1)
  );

  // form onsubmit
  const [leave, setLeave] = useState([
    updateLeaveData?.start_date ? new Date(updateLeaveData?.start_date) : null,
    updateLeaveData?.end_date ? new Date(updateLeaveData?.end_date) : null,
  ]);

  const { handleSubmit, control, watch, reset } = useForm();
  const leaveType = watch("leave_type");
  const [leaveTypeDay, setLeaveTypeDay] = useState(0);
  useEffect(() => {
    setLeaveTypeDay(leaveType?.label?.split(" (")[1]?.split(")")[0]);
  }, [watch("leave_type")?.label]);
  const onSubmit = async (value) => {
    const leaveTypeValue = value?.leave_type?.value
      ? value?.leave_type?.value
      : "";
    setFilteredResults([]);
    switch (leaveType?.value) {
      case 1:
        const leaveList = await getAllLeavesBetweenDates.refetch({
          startDate: String(
            new Date(`${moment(startDate).format("YYYY-MM-DD")} 00:00:00`)
          ),
          endDate: String(
            new Date(`${moment(endDate).format("YYYY-MM-DD")} 00:00:00`)
          ),
        });
        setFilteredResults(
          leaveList?.data?.getAllLeavesBetweenDates?.filter((item) =>
            [1, 2, 3, 4, 5]?.includes(item?.leave_type)
          )
        );
        break;
      case 2:
        // filter late attendance: after 10:15:59
        const lateAttendance = await searchAttendance.refetch({
          startedDate: moment(new Date(startDate)).format("YYYY-MM-DD"),
          endDate: moment(new Date(endDate)).format("YYYY-MM-DD"),
          user_id: Number(value?.user_id?.value),
        });

        const filterByLoginTime =
          lateAttendance?.data?.search_attendance_between_date_and_rfid
            ?.map((item) => ({
              ...item,
              loginTime: item?.login_time?.split(" ")[1],
            }))
            ?.map((item) => {
              const date = item?.login_time?.split(" ")[0];
              const time = item?.login_time?.split(" ")[1];
              if (moment(`${date} ${time}`).isAfter(`${date} 10:30:00`)) {
                return { ...item, lateEntry: true };
              }
              return { ...item, lateEntry: false };
            });
        setFilteredResults(filterByLoginTime);
        break;
      case 3:
        const efhOrOfd = await getAllLeavesBetweenDates.refetch({
          startDate: String(startDate),
          endDate: String(endDate),
        });
        setFilteredResults(
          efhOrOfd?.data?.getAllLeavesBetweenDates?.filter(
            (item) => item?.leave_type == 101 || item?.leave_type == 102
          )
        );
        break;

      default:
        break;
    }
  };

  // leave type array

  const leaveTypeList = [
    {
      value: 1,
      label: "Leave",
    },
    // {
    //   value: 2,
    //   label: "Late Entry",
    // },
    {
      value: 3,
      label: "WFH/OFD",
    },
  ];
  const leaveTypeList2 = [
    {
      value: 1,
      label: "Casual",
    },
    {
      value: 2,
      label: "Anual",
    },
    {
      value: 3,
      label: "Sick",
    },
    {
      value: 4,
      label: "Menstrual",
    },
    {
      value: 5,
      label: "Maternity",
    },
    {
      value: 6,
      label: `Reimburse`,
    },
  ];
  // attendance type array
  const attendanceTypeArray = [
    {
      value: 101,
      label: `Work From Home`,
    },
    {
      value: 102,
      label: `On Field Duty`,
    },
    {
      value: 201,
      label: `On Leave`,
    },
  ];

  useEffect(() => {
    if (moment.duration(leave?.[1] - leave[0]).asDays() + 1 > leaveTypeDay) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [leaveTypeDay]);
  const [excelData, setExcelData] = useState([]);
  useEffect(() => {
    switch (tableDisplayType) {
      case 1:
      case 3:
        setExcelData(
          filteredResults?.map((item, index) => {
            const leave_type = leaveTypeList2.find(
              (leaveItem) => leaveItem?.value === item?.leave_type
            )?.label;
            const attendance_type = attendanceTypeArray?.find(
              (attendanceType) => attendanceType?.value == item?.leave_type
            )?.label;
            return {
              id: index + 1,
              name: item?.user?.name + ` (${item?.user?.username})`,
              start_date: moment(new Date(item?.start_date)).format(
                "YYYY-MM-DD"
              ),
              end_date: moment(new Date(item?.end_date)).format("YYYY-MM-DD"),
              dayCount:
                moment
                  .duration(
                    new Date(item?.end_date) - new Date(item?.start_date)
                  )
                  .asDays() + 1,
              leave_reason: item?.leave_reason,
              leave_type:
                (leave_type && leave_type) ||
                (attendance_type && attendance_type),
              leave_status: item?.leave_status,
            };
          })
        );
        break;
      case 2:
        setExcelData(
          filteredResults?.map((item, index) => {
            return {
              id: index + 1,
              name:
                item?.employeeInfo?.user?.name +
                ` (${item?.employeeInfo?.user?.username})`,
              login_date: item?.login_date ? item?.login_date : "—",
              login_time: item?.login_time
                ? moment(new Date(item?.login_time)).format("hh:mm:ss A") +
                  `${item?.login_device ? " (" + item?.login_device + ")" : ""}`
                : "—",
              logout_time: item?.logout_time
                ? moment(new Date(item?.logout_time)).format("hh:mm:ss A") +
                  `${
                    item?.logout_device ? " (" + item?.logout_device + ")" : ""
                  }`
                : "—",
              duration: item?.logout_time
                ? moment
                    .utc(
                      moment(item?.logout_time, "YYYY-MM-DD HH:mm:ss").diff(
                        moment(item?.login_time, "YYYY-MM-DD HH:mm:ss")
                      )
                    )
                    .format("HH:mm:ss")
                : "—",
            };
          })
        );
        break;

      default:
        break;
    }
  }, [filteredResults?.length]);

  useEffect(() => {
    setTableDisplayType(leaveType?.value); // it is using for display table
    setFilteredResults([]);
    setExcelData([]);
  }, [leaveType?.value]);

  // pdf download handler
  const pdfDownloadHandler = async (data) => {
    // group by user id for making table
    const tableData = await data?.reduce((group, leave) => {
      const { user_id } = leave;
      group[user_id] = group[user_id] ?? [];
      group[user_id].push(leave);
      return group;
    }, []);

    // making array for table data
    tableData?.map((item) => {
      const body = [];

      // body data
      item?.map((item, index) => {
        const name = item?.user?.name;
        const start = moment(item?.start_date).format("DD/MM/YYYY");
        const end = moment(item?.end_date).format("DD/MM/YYYY");
        const day =
          moment
            .duration(new Date(item?.end_date) - new Date(item?.start_date))
            .asDays() + 1;
        const leaveType = leaveTypeList2.find(
          (leaveItem) => leaveItem?.value === item?.leave_type
        )?.label;
        const attendanceType = attendanceTypeArray?.find(
          (attendanceType) => attendanceType?.value == item?.leave_type
        )?.label;
        body.push([
          index + 1,
          name,
          start,
          end,
          day,
          leaveType,
          item?.leave_status,
          item?.leave_reason,
        ]);
      });

      //  calculation of individual total day of leave
      const dayCount = body
        ?.map((item) => {
          return item[4];
        })
        ?.reduce((previousValue, currentValue) => previousValue + currentValue);
      body?.push(["Total", `${dayCount} ${dayCount > 1 ? " days" : " day"}`]);

      // calling for making table
      doc.autoTable({
        theme: "grid",
        head: [
          ["#", "Name", "Start", "End", "Day", "Type", "Status", "Reasons"],
        ],
        body,
        rowPageBreak: "avoid",
      });
    });
    doc.save(`Report_${moment(new Date())}.pdf`);
    setFilteredResults([]);
  };

  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Reports",
    },
  ];
  console.log(
    "🚀 ~ Reports ~ getAllLeavesBetweenDates:",
    getAllLeavesBetweenDates,
    searchAttendance,
    LoadAllUsers,
    LoadAllLeaves
  );
  if (
    getAllLeavesBetweenDates.loading ||
    searchAttendance.loading ||
    LoadAllUsers.loading ||
    LoadAllLeaves.loading
  ) {
    return <p>loading...</p>;
  }
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Row>
            <Col>
              <BreadCrumb breadCrumbData={breadCrumbData} />
            </Col>
          </Row>
          <Row>
            <Col xs={4} md={6}>
              <h3 className="page-title">Reports</h3>
            </Col>
            <Col xs={8} md={6} className="flex flex-row justify-content-end">
              {excelData?.length > 0 && (
                <ExcelFile
                  element={
                    <button className="btn ml-2 btn-sm btn-primary float-right">
                      Excel <MdOutlineDownloading className="mb-1" />
                    </button>
                  }
                >
                  {(() => {
                    switch (tableDisplayType) {
                      case 1:
                      case 3:
                        return (
                          <ExcelSheet
                            data={excelData}
                            name={`${
                              [1]?.includes(tableDisplayType)
                                ? "Leave"
                                : [2]?.includes(tableDisplayType)
                                ? "Late_Entry"
                                : "WFH_OFD"
                            }_from_${moment(startDate).format(
                              "YYYY-MM-DD"
                            )}_to_${moment(endDate).format(
                              "YYYY-MM-DD"
                            )}_${moment(new Date()).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}`}
                          >
                            <ExcelColumn label="SL" value="id" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Start" value="start_date" />
                            <ExcelColumn label="End" value="end_date" />
                            <ExcelColumn label="Reason" value="leave_reason" />
                            <ExcelColumn label="Type" value="leave_type" />
                            <ExcelColumn label="Status" value="leave_status" />
                          </ExcelSheet>
                        );
                      case 2:
                        return (
                          <ExcelSheet
                            data={excelData}
                            name={`${
                              [1]?.includes(tableDisplayType)
                                ? "Leave"
                                : [2]?.includes(tableDisplayType)
                                ? "Late_Entry"
                                : "WFH_OFD"
                            }_from_${moment(startDate).format(
                              "YYYY-MM-DD"
                            )}_to_${moment(endDate).format(
                              "YYYY-MM-DD"
                            )}_${moment(new Date()).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}`}
                          >
                            <ExcelColumn label="SL" value="id" />
                            <ExcelColumn label="Name" value="name" />
                            <ExcelColumn label="Date" value="login_date" />
                            <ExcelColumn label="Login" value="login_time" />
                            <ExcelColumn label="Logout" value="logout_time" />
                            <ExcelColumn label="Duration" value="duration" />
                          </ExcelSheet>
                        );

                      default:
                        break;
                    }
                  })()}
                </ExcelFile>
              )}
              {filteredResults?.length > 0 && (
                <span>
                  <button
                    onClick={() => pdfDownloadHandler(filteredResults)}
                    className="btn ml-2 btn-sm btn-primary float-right"
                  >
                    PDF <MdOutlineDownloading className="mb-1" />
                  </button>
                </span>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={10} className="mx-auto">
              <form
                className="form justify-content-center"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Col xs={6} md={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{`Start Date`}</span>
                    <div className="form__form-group-field leave-calender">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={startDate}
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        showWeekNumbers
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={6} md={2}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">{`End Date`}</span>
                    <div className="form__form-group-field leave-calender">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        selected={endDate}
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => {
                          setEndDate(date);
                        }}
                        showWeekNumbers
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={2}>
                  <div className="form__form-group mr-3">
                    <span className="form__form-group-label">Leave type</span>
                    <div className="form__form-group-field">
                      <FormField
                        name="leave_type"
                        control={control}
                        component={SelectField}
                        options={leaveTypeList}
                        placeholder="Select leave type"
                        rules={{ required: "This is required field" }}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={3}>
                  <div className="form__form-group">
                    <span className="form__form-group-label"></span>
                    <div className="form__form-group-field">
                      <Button type="submit"> Search </Button>
                      <Button
                        onClick={() => {
                          reset({ user_id: "", leave_type: "" });
                          setStartDate(prevDateOfMonth);
                          setEndDate(new Date());
                          setFilteredResults([]);
                          setTableDisplayType(null);
                        }}
                      >
                        {" "}
                        Clear{" "}
                      </Button>
                    </div>
                  </div>
                </Col>
              </form>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <h5 className="text-success">
                <i>
                  {filteredResults?.length > 0
                    ? (() => {
                        switch (tableDisplayType) {
                          case 1:
                          case 3:
                            return (
                              <span>
                                Search from {moment(startDate).format("LL")} to{" "}
                                {moment(endDate).format("LL")}, Application
                                found {filteredResults?.length}
                              </span>
                            );
                          case 2:
                            return (
                              <span>
                                Search from {moment(startDate).format("LL")} to{" "}
                                {moment(endDate).format("LL")}, Late entry found{" "}
                                {
                                  filteredResults?.filter(
                                    (item) => item?.lateEntry
                                  )?.length
                                }{" "}
                                out of {filteredResults?.length}
                              </span>
                            );

                          default:
                            break;
                        }
                      })()
                    : ""}
                </i>
              </h5>
            </Col>
          </Row>

          <Row>
            {(() => {
              switch (tableDisplayType) {
                case 1:
                case 3:
                  return (
                    filteredResults?.length > 0 && (
                      <ReportsTable
                        allLeaves={filteredResults?.map((item, index) => ({
                          ...item,
                          indexId: index + 1,
                        }))}
                      />
                    )
                  );
                case 2:
                  return (
                    filteredResults?.length > 0 && (
                      <AttendanceTable
                        allAttendances={filteredResults?.map((item, index) => ({
                          ...item,
                          indexId: index + 1,
                        }))}
                        isLoadingAttendance={searchAttendance?.loading}
                        roles={roles}
                      />
                    )
                  );

                default:
                  break;
              }
            })()}
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Reports;
