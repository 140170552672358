// import { useMutation } from "@apollo/client";
// import React, { useState } from "react";
// import {
//   Col,
//   Form,
//   FormGroup,
//   Label,
//   ModalBody,
//   ModalFooter,
//   ModalHeader,
//   Row,
// } from "reactstrap";
// import { CREATE_ROLE } from "../../GraphQL/Mutation";
// import BreadCrumb from "../../shared/components/BreadCrumb/BreadCrumb";

// const AddRole = ({ toggle, refetch }) => {
//   const [roleValue, setRoleValue] = useState("");
//   const [createRole, roleData] = useMutation(CREATE_ROLE);
//   const handleRole = async () => {
//     await createRole({
//       variables: {
//         role_name: roleValue,
//       },
//     });
//     setRoleValue("");
//     await refetch();
//     toggle();
//   };
//   const breadCrumbData = [
//     {
//       name: "Dashboard",
//       link: "/",
//     },
//     {
//       name: "Add Role",
//     },
//   ];

//   return (
//     <>
//       <ModalHeader>
//         <BreadCrumb breadCrumbData={breadCrumbData} />

//         <h1>Create Role</h1>
//       </ModalHeader>
//       <ModalBody>
//         <Form>
//           <FormGroup as={Row} controlId="formPlaintextEmail">
//             <Label column sm="4">
//               Enter Role
//             </Label>
//             <Col sm="6">
//               <input
//                 className="form-control"
//                 value={roleValue}
//                 onChange={(e) => {
//                   setRoleValue(e.target.value);
//                 }}
//                 placeholder="Enter Role Name"
//               />
//             </Col>
//           </FormGroup>
//         </Form>
//       </ModalBody>
//       <ModalFooter>
//         <button className="btn btn-danger" onClick={toggle}>
//           Cancel
//         </button>
//         <button className="btn btn-success" onClick={handleRole}>
//           Save
//         </button>
//       </ModalFooter>
//     </>
//   );
// };

// export default AddRole;

import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Col,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import { CREATE_ROLE } from "../../GraphQL/Mutation";
import Swal from "sweetalert2";

const AddRole = ({ toggle, refetch }) => {
  const [roleValue, setRoleValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [createRole] = useMutation(CREATE_ROLE, {
    onError: (error) => {
      // Handle error if mutation fails
      console.error("Error creating role:", error);
    },
  });

  const handleRole = async () => {
    if (!roleValue) {
      // If no value, prevent submission
      Swal.fire({
        icon: "error",
        title: "Role name cannot be empty",
        text: "Please provide a valid role name to proceed.",
      });
      return;
    }

    setLoading(true);

    try {
      await createRole({
        variables: {
          role_name: roleValue,
        },
      });
      setRoleValue(""); // Clear input field after mutation
      await refetch(); // Refetch data to update the UI
      toggle(); // Close the modal after successful mutation
    } catch (error) {
      console.error("Error creating role:", error); // Optional: Show error to user
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <>
      <ModalHeader>
        <h1>Create Role</h1>
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup as={Row}>
            <Col sm={12}>
              <Label for="role">Role Name</Label>
              <input
                type="text"
                id="role"
                className="form-control"
                value={roleValue}
                onChange={(e) => setRoleValue(e.target.value)}
                placeholder="Enter role name"
              />
            </Col>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleRole}
          disabled={loading} // Disable button when loading
        >
          {loading ? <Spinner size="sm" /> : "Create"}
        </Button>
        <Button color="btn btn-danger" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );
};

export default AddRole;
