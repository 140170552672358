import React, { useEffect, useState } from "react";
import { isEmptyObject } from "../../../utils/utilities";
import { loginInfo, onlyTime } from "../../../utils/helpers";
import AttendanceReportSearchForm from "../../Attendance/Report/Search/Components/SearchForm";
import useAttendanceSummaryQuery from "../../../GraphQL/hooks/queries/useAttendanceSummaryQuery";
import AttendanceReportUserProfile from "../../Attendance/Report/SearchResult/Components/UserProfile";
import { Card, CardBody, Col, Row } from "reactstrap";
import AttendanceReportTimeSlotSummary from "../../Attendance/Report/SearchResult/Components/TimeSlotSummary";
import AttendanceReportWorkingTime from "../../Attendance/Report/SearchResult/Components/WorkingTime";
import AttendanceReportWorkingHistory from "../../Attendance/Report/SearchResult/Components/WorkingHistory";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";

function OverTime() {
  const [results, setResults] = useState({});
  const [searchData, setSearchData] = useState({});
  const isEmptyResults = isEmptyObject(results);
  const isEmptySearchData = isEmptyObject(searchData);
  const loggedInfo = loginInfo();
  const LoadAttendanceSummary = useAttendanceSummaryQuery({
    variables: {
      input: {},
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });
  const onClear = () => {
    setSearchData({});
    setResults({});
  };

  const now = new Date();

  // Set today's date as both the start and end date
  const startDate = new Date(now);

  const endDate = new Date(now);

  // Get the current year and month
  //   const year = now.getFullYear();
  //   const month = now.getMonth(); // Note: month is 0-based (January is 0)

  //   // Get the first day of the current month
  //   const startDate = new Date(year, month, 1);

  //   // Get the last day of the current month by setting the date to 0 of the next month
  //   const endDate = new Date(year, month + 1, 0);

  useEffect(() => {
    const fetchData = async () => {
      const input = {
        start_date: new Date(startDate).getTime(),
        end_date: new Date(endDate).getTime(),
        user_id: loggedInfo?.id,
      };

      // Fetch the attendance summary data
      const searchedData = await LoadAttendanceSummary?.refetch({
        input,
      });

      // Update state with the fetched data
      setSearchData(input);
      setResults(searchedData?.data?.attendanceSummary);
    };

    // Call the fetchData function on component mount
    fetchData();
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const data = results;
  const attendanceTimeSlot = data?.workingHour?.work_hour_report_history;

  // Function to convert time string to seconds
  function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds; // Convert to total seconds
  }

  // Function to calculate late logins (9:30 AM or later)
  function calculateLateLogins(attendanceTimeSlot) {
    const lateLoginThreshold = "09:30:10"; // Late if login time is 9:30 AM or later
    const lateLoginThresholdInSeconds = timeToSeconds(lateLoginThreshold); // Convert threshold to seconds
    let lateCount = 0;

    attendanceTimeSlot?.forEach((entry) => {
      // Extract the login time (HH:MM:SS) from the entry
      const loginTime = entry.login_time.split(" ")[1]; // Get time portion from 'YYYY-MM-DD HH:MM:SS'
      const loginTimeInSeconds = timeToSeconds(loginTime); // Convert login time to seconds

      // Compare login time with the late threshold (in seconds)
      if (loginTimeInSeconds >= lateLoginThresholdInSeconds) {
        lateCount++;
        console.log(`Late login detected: ${entry.login_date} at ${loginTime}`); // Log each late login
      }
    });

    return lateCount;
  }

  const lateLogins = calculateLateLogins(attendanceTimeSlot);
  console.log(`Number of late logins: ${lateLogins}`);

  // // Function to calculate penalty based on late logins
  const penaltyDays = Math.floor(lateLogins / 4); // Alternatively, you can use: const penaltyDays = ~~(lateLogins / 4);
  console.log(`Number of penalty days: ${penaltyDays}`);
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Overtime Work ",
    },
  ];
  return (
    <>
      <BreadCrumb breadCrumbData={breadCrumbData} />

      <Row>
        <p style={{ margin: "10px" }}>Overtime under construction...</p>
        {/* <Col md={5}>
          <Card>
            <CardBody>
              <AttendanceReportUserProfile data={data?.user} />
              <hr />
            </CardBody>
          </Card>
        </Col>
        <Col md={7}>
          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AttendanceReportWorkingHistory
                    data={data?.workingHour?.work_hour_report_history}
                    workingHour={data?.workingHour}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col md={12}>
              <Card>
                <CardBody>
                  <AttendanceReportWorkingTime
                    lateLogins={lateLogins}
                    leaveDeduct={penaltyDays}
                    data={data?.workingHour}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </>
  );
}

export default OverTime;
