import { Table } from "reactstrap";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_COMPANIES,
  GET_ALL_DEPARTMENT,
  GET_ALL_DESIGNATION,
  GET_ALL_USER,
} from "../../../../GraphQL/Queries";
import { useHistory } from "react-router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  COMPANY_SOFT_DELETE,
  DELETE_DEPARTMENT_BY_ID,
  DEPARTMENT_DELETED_STATUS_UPDATE,
} from "../../../../GraphQL/Mutation";
import { successMessage } from "../../../ReactToastify";
import { decryption } from "../../../HelperFunctions";
import Swal from "sweetalert2";
import ReadMore from "../../../../utils/HelperComponent/ReadMore";
import axios from "axios";
import BASE_URL from "../../../../utils/api/baseUrl";

const CompanyListTable = () => {
  const [allCompanies, setAllCompanies] = useState([]);
  const [deleteDeptId, setDeleteDeptId] = useState(null);
  const LoadAllCompanies = useQuery(GET_ALL_COMPANIES);
  const [companySoftDelete, companySoftDeleteFeedback] =
    useMutation(COMPANY_SOFT_DELETE);
  const history = useHistory();
  const UserList = useQuery(GET_ALL_USER);
  const DesigList = useQuery(GET_ALL_DESIGNATION);
  const [deleteToggle, setDeleteToggle] = useState(false);
  useEffect(() => {
    setAllCompanies(LoadAllCompanies?.data?.getAllCompanies);
    LoadAllCompanies?.refetch();
  }, [LoadAllCompanies?.data?.getAllCompanies, deleteToggle]);
  const deleteHandler = async (deleteData) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then(async (result) => {
      // before showing confirm modal
      if (result.isConfirmed) {
        // company delete api call
        const result = await companySoftDelete({
          variables: {
            id: deleteData?.id,
          },
        });

        // if company deleted successfully then delete logo
        await axios.post(
          `${BASE_URL}/api/image/delete`,
          { oldImage: deleteData?.logo },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        // if company deleted successfully then show message
        if (result?.data?.companySoftDelete?.result_code === "000") {
          Swal.fire(
            "Success!",
            "Company has been successfully deleted!",
            "success"
          );
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
        }
        LoadAllCompanies?.refetch();
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        Swal.fire("Cancelled", "Company is safe :)", "error");
      }
    });
  };
  // permission
  const permissions = decryption(
    JSON.parse(localStorage?.getItem("permissions"))
  );

  // delete loading
  if (companySoftDeleteFeedback?.loading) {
    Swal.showLoading();
  }
  return (
    <Table role="table" striped hover responsive>
      <thead>
        <tr role="row">
          <th style={{ width: "5%" }}>#</th>
          <th style={{ width: "5%" }}>Logo</th>
          <th style={{ width: "20%" }}>Name</th>
          <th style={{ width: "50%" }}>Description</th>
          <th style={{ width: "20%", textAlign: "center" }}>Action</th>
        </tr>
      </thead>
      <tbody>
        {allCompanies?.length > 0 ? (
          allCompanies?.map((item, index) => (
            <tr key={item?.id}>
              <th style={{ width: "5%" }}>{index + 1}</th>
              <td
                style={{ width: "5%" }}
                role="cell"
                onClick={() => {
                  userToggle();
                  setUserDetails(item);
                }}
              >
                <div className="employee-list__img-wrap">
                  <img
                    src={
                      item?.logo ??
                      "https://st3.depositphotos.com/23594922/31822/v/600/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg"
                    }
                    alt="image"
                  />
                </div>
              </td>
              <td style={{ width: "20%" }}>{item?.name}</td>
              <td style={{ width: "50%" }}>
                <ReadMore children={item?.description} />
              </td>
              <td style={{ width: "20%", textAlign: "center" }}>
                {permissions?.includes("delete_company") && (
                  <button
                    className="inbox__email-btn"
                    type="button"
                    aria-label="edit"
                    onClick={() => {
                      deleteHandler(item);
                      setDeleteDeptId(item?.id);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                )}
                {permissions?.includes("update_company") && (
                  <button
                    className="inbox__email-btn mr-1"
                    type="button"
                    aria-label="edit"
                    onClick={() =>
                      history.push({
                        pathname: "/company/new",
                        state: {
                          data: item,
                        },
                      })
                    }
                  >
                    <EditIcon fontSize="small" />
                  </button>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="4" className="text-success text-center">
              <b>
                There is no registered company. To add a new company, please
                click the 'New Company' button.
              </b>
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default CompanyListTable;
