import React, { useState } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { SelectField } from "@/shared/components/form/Select";
import FormField from "../FormField";
import { useHistory } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_DEPARTMENT,
  UPDATE_DEPARTMENT,
} from "../../../../GraphQL/Mutation";
import { useDispatch } from "react-redux";
import { deptAction } from "../../../../redux/actions/deptAction";
import { successMessage } from "../../../ReactToastify";
import { GET_ALL_USER } from "../../../../GraphQL/Queries";

const NewDepartment = () => {
  const history = useHistory();
  const updateDept = history?.location?.state?.dept;
  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control } = useForm();
  const UserList = useQuery(GET_ALL_USER);
  const [createDepartment] = useMutation(CREATE_DEPARTMENT);
  const [updateDepartment] = useMutation(UPDATE_DEPARTMENT);
  const onSubmit = async (data) => {
    setIsLoading(true);
    const deptData = {
      name: data?.name,
      description: data?.description,
      user_id: Number(data?.user_id?.value),
    };
    if (updateDept?.id) {
      await updateDepartment({
        variables: {
          ...deptData,
          id: updateDept?.id,
        },
      });
      successMessage("Update successfully...", 1500);
      history.push("/department/list");
      setIsLoading(false);
    } else {
      await createDepartment({
        variables: deptData,
      });
      successMessage("Create successfully...", 1500);
      history.push("/department/list");
      setIsLoading(false);
    }
  };
  return (
    <Col md={12}>
      <Col md={12}>
        <h3 className="page-title">
          {updateDept?.id ? "Update" : "New"} Department
        </h3>
      </Col>
      <Card>
        <CardBody>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={8} className="mx-auto">
              <Row>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Name</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="name"
                        component="input"
                        control={control}
                        rules={{ required: "This is required field" }}
                        type="text"
                        defaultValue={updateDept?.name}
                        placeholder={`Enter department name`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Head of the department
                    </span>
                    <div className="form__form-group-field">
                      <FormField
                        name="user_id"
                        control={control}
                        component={SelectField}
                        options={UserList?.data?.getUser?.map((data) => ({
                          value: data?.id,
                          label: `${data?.name} (${data?.username})`,
                        }))}
                        defaultValue={UserList?.data?.getUser
                          ?.filter((item) => item?.id == updateDept?.user_id)
                          ?.map((data) => ({
                            value: data?.id,
                            label: `${data?.name} (${data?.username})`,
                          }))}
                        placeholder="Select head of the department"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">
                      Description
                    </label>
                    <div className="form__form-group-field">
                      <FormField
                        name="description"
                        component="textarea"
                        control={control}
                        type="text"
                        defaultValue={updateDept?.description}
                        placeholder={`Enter department description`}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/department/list")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>
                      <Button
                        disabled={isLoading}
                        color="primary"
                        type="submit"
                      >
                        {updateDept?.id ? "Update" : "Create"}
                      </Button>
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewDepartment;
