import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_ACTIVE_NOTICE } from "../../../../GraphQL/Queries";
import { Modal } from "reactstrap";
import TableDataLoading from "../../../../utils/HelperComponent/TableDataLoading";
import NoticeDetailsModal from "./NoticeDetailsModal";
import { loginInfo } from "../../../../utils/helpers";
import Swal from "sweetalert2";

export default function NoticeLoginPage() {
  const loggedInfo = loginInfo();
  const { data, loading } = useQuery(GET_ACTIVE_NOTICE);

  const [userModal, setUserModal] = useState(false);
  const [noticeDetails, setNoticeDetails] = useState({});

  const userToggle = () => {
    setUserModal((prevState) => !prevState);
  };

  // const handleNoticeClick = (item) => {
  //   if (loggedInfo?.id) {
  //     // If logged in, show the Notice Details Modal
  //     setNoticeDetails(item);
  //     userToggle();
  //   } else {
  //     // If not logged in, show login prompt using Swal
  //     Swal.fire({
  //       icon: "warning",
  //       title: "Please Log In",
  //       text: "You need to be logged in to view notice details.",
  //       confirmButtonText: "OK",
  //     });
  //   }
  // };

  return (
    <div
      style={{
        padding: "12px",
        // minWidth: "520px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Adds a soft shadow for depth
      }}
    >
      <h3
        style={{
          paddingTop: "10px",
          color: "#333",
          textAlign: "center",
          fontWeight: "bold",
        }}
      >
        📢 Important Notices
      </h3>
      <div
        style={{
          paddingRight: "20px",
          paddingLeft: "20px",
          paddingTop: "20px",
          paddingBottom: "10px",
          maxWidth: "800px",
          margin: "auto",
        }}
      >
        {loading ? (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div
              className="spinner-border text-secondary"
              role="status"
              style={{ width: "1rem", height: "1rem" }}
            >
              {/* <span className="visually-hidden">Loading...</span> */}
            </div>
          </div>
        ) : // <p className="text-center">Loading...</p>
        // <TableDataLoading className="text-center" colSpan="2" />
        data?.getActiveNotice?.length ? (
          data.getActiveNotice.slice(0, 5).map((item, index) => (
            <div
              key={index}
              onClick={() => {
                userToggle();
                setNoticeDetails(item);
              }}
              style={{
                backgroundColor: "#f3f4f6", // Lighter background for a modern look
                padding: "10px",
                marginBottom: "10px",
                borderRadius: "8px",
                boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)", // Adds subtle shadow
                transition: "transform 0.2s ease",
                cursor: "pointer",
              }}
            >
              <h5 style={{ margin: "0", fontWeight: "600", fontSize: "16px" }}>
                {index + 1}.{" "}
                {item?.name.length > 50
                  ? `${item?.name.substring(0, 50)}...`
                  : item?.name}{" "}
              </h5>
              <span>
                Publish Date:{" "}
                {item?.createdAt && !isNaN(item.createdAt)
                  ? new Date(Number(item.createdAt)).toLocaleDateString(
                      "en-US",
                      {
                        weekday: "long",
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      }
                    )
                  : "Date not available"}
              </span>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", color: "#888" }}>
            No notices available
          </p>
        )}
      </div>

      <NoticeDetailsModal
        toggle={userToggle}
        isOpen={userModal}
        notice={noticeDetails}
      />
      {/* Responsive Styling */}
      <style>
        {`
          @media (max-width: 600px) {
            div[style*="padding: 20px; max-width: 800px; margin: auto;"] {
              padding: 10px;
            }

            h4 {
              font-size: 16px;
            }

            p[style*="font-size: 14px"] {
              font-size: 13px;
            }

            p[style*="font-size: 12px"] {
              font-size: 11px;
            }
          }
        `}
      </style>
    </div>
  );
}
