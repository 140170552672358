import React from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import AttendanceReportUserProfile from "./Components/UserProfile";
import AttendanceReportTimeSlotSummary from "./Components/TimeSlotSummary";
import AttendanceReportWorkingTime from "./Components/WorkingTime";
import AttendanceReportWorkingHistory from "./Components/WorkingHistory";

const AttendanceSummarySearchResult = ({ data }) => {
  const attendanceTimeSlot = data?.workingHour?.work_hour_report_history;

  // Function to convert time string to seconds
  function timeToSeconds(time) {
    const [hours, minutes, seconds] = time.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds; // Convert to total seconds
  }

  // Function to calculate late logins (9:30 AM or later)
  function calculateLateLogins(attendanceTimeSlot) {
    const lateLoginThreshold = "09:30:10"; // Late if login time is 9:30 AM or later
    const lateLoginThresholdInSeconds = timeToSeconds(lateLoginThreshold); // Convert threshold to seconds
    let lateCount = 0;

    attendanceTimeSlot?.forEach((entry) => {
      // Extract the login time (HH:MM:SS) from the entry
      const loginTime = entry.login_time.split(" ")[1]; // Get time portion from 'YYYY-MM-DD HH:MM:SS'
      const loginTimeInSeconds = timeToSeconds(loginTime); // Convert login time to seconds

      // Compare login time with the late threshold (in seconds)
      if (loginTimeInSeconds >= lateLoginThresholdInSeconds) {
        lateCount++;
        console.log(`Late login detected: ${entry.login_date} at ${loginTime}`); // Log each late login
      }
    });

    return lateCount;
  }

  const lateLogins = calculateLateLogins(attendanceTimeSlot);

  // // Function to calculate penalty based on late logins
  const penaltyDays = Math.floor(lateLogins / 4); // Alternatively, you can use: const penaltyDays = ~~(lateLogins / 4);

  return (
    <Row>
      <Col md={4}>
        <Card>
          <CardBody>
            <AttendanceReportUserProfile data={data?.user} />
            <hr />
          </CardBody>
        </Card>
      </Col>
      <Col md={8}>
        <Row>
          <Col md={12}>
            <Card>
              <CardBody>
                <AttendanceReportTimeSlotSummary data={data} />
              </CardBody>
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              {/* <CardBody>
                <AttendanceReportWorkingTime
                  lateLogins={lateLogins}
                  leaveDeduct={penaltyDays}
                  data={data?.workingHour}
                />
              </CardBody> */}
            </Card>
          </Col>
          <Col md={12}>
            <Card>
              <CardBody>
                <AttendanceReportWorkingHistory
                  data={data?.workingHour?.work_hour_report_history}
                  workingHour={data?.workingHour}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AttendanceSummarySearchResult;
