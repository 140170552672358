import React, { useEffect } from "react";
import { Button, ButtonToolbar, Card, CardBody, Col, Row } from "reactstrap";
import { SelectField } from "@/shared/components/form/Select";
import { useForm } from "react-hook-form";
import FormField from "./FormField";
import CheckIcon from "mdi-react/CheckIcon";

import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import BASE_URL from "../../../utils/api/baseUrl";
import axios from "axios";
import { useState } from "react";
import { useHistory } from "react-router";
import DatePicker from "react-datepicker";

import jwt from "jsonwebtoken";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_LEAVE, UPDATE_LEAVE } from "../../../GraphQL/Mutation";
import { successMessage, warningMessage } from "../../ReactToastify";
import moment from "moment";
import {
  GET_ALL_ADDITIONAL_INFO,
  GET_ALL_ADDITIONAL_INFO_BY_DEPT_ID,
  GET_ALL_ADDITIONAL_INFO_BY_RFID,
  GET_ALL_ADDITIONAL_INFO_BY_USER_ID,
  GET_ALL_DEPARTMENT,
  GET_ALL_LEAVES_BY_USER_ID,
  GET_USER_BY_RFID,
} from "../../../GraphQL/Queries";
import { decryption } from "../../HelperFunctions";
import {
  checkWithDate,
  dateRangeArray,
  loginInfo,
  isExistApllication,
} from "../../../utils/helpers";
import useLeaveApplicationDropdownsQuery from "../../../GraphQL/hooks/queries/useLeaveApplicationDropdownsQuery";
import BreadCrumb from "../../../shared/components/BreadCrumb/BreadCrumb";
import Swal from "sweetalert2";

const LeaveForm = () => {
  const loggedInfo = loginInfo();
  // ** this form global state
  const [leaveForm, setLeaveForm] = useState({
    leaveTypes: [],
    relievers: [],
  });

  const history = useHistory();

  const updateLeaveData = history?.location?.state?.leave;

  // ** leave type query
  const LoadApplicationDropdownData = useLeaveApplicationDropdownsQuery({
    variables: {
      input: {
        user_id: Number(loggedInfo?.id),
      },
    },
  });
  console.log(
    "🚀 ~ LeaveForm ~ LoadApplicationDropdownData:",
    LoadApplicationDropdownData?.loading
  );

  // ** set leave types
  useEffect(() => {
    setLeaveForm((prevState) => ({
      ...prevState,
      leaveTypes: LoadApplicationDropdownData?.data?.leaveTypes,
      relievers: LoadApplicationDropdownData?.data?.relievers,
    }));
  }, [LoadApplicationDropdownData?.data]);

  //get token from the local storage;
  const token = localStorage?.getItem("token");
  let user;
  //verify that token ;
  jwt.verify(token, "test", function (err, decoded) {
    if (err) {
      localStorage.removeItem("token");
    }
    user = decoded;
  });
  const [isDisabled, setIsDisabled] = useState(true);
  const [createLeave] = useMutation(CREATE_LEAVE);
  const [updateLeave] = useMutation(UPDATE_LEAVE);
  const LoadAdditionalInfo = useQuery(GET_ALL_ADDITIONAL_INFO);
  const LoadHeadOfDepartment = useQuery(GET_ALL_DEPARTMENT);
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [images, setImages] = useState(
    updateLeaveData?.id ? JSON.parse(updateLeaveData?.images) : []
  );

  const [isChecked, setIsChecked] = useState(
    updateLeaveData?.id ? (images?.length ? true : false) : false
  );
  const [employeeByDepartment, setEmployeeByDepartment] = useState([]);
  const [leaveLength, setLeaveLength] = useState(0);

  // queries
  const getAllLeavesByUserId = useQuery(GET_ALL_LEAVES_BY_USER_ID, {
    skip: !loggedInfo?.id,
    variables: {
      user_id: Number(loggedInfo?.id),
    },
  });
  console.log(
    "🚀 ~ LeaveForm ~ getAllLeavesByUserId:",
    getAllLeavesByUserId?.loading
  );

  // leave_status  "Denied"
  // check if already exist leave application
  const existLeaveApplication = checkWithDate(
    getAllLeavesByUserId?.data?.getAllLeavesByUserId
      ?.filter((item) => item?.leave_status !== "Denied")
      ?.map((item) => ({
        start_date: moment(new Date(item?.start_date)).format("YYYY-MM-DD"),
        end_date: moment(new Date(item?.end_date)).format("YYYY-MM-DD"),
        id: item?.id,
      }))
  );

  // login user information
  const loginUserInfo = useQuery(GET_ALL_ADDITIONAL_INFO_BY_USER_ID, {
    variables: { user_id: user?.id },
  });

  // roles
  const roles = decryption(JSON.parse(localStorage?.getItem("roles")));

  // get employee department wise
  const LoadEmployeeByDepartment = useQuery(
    GET_ALL_ADDITIONAL_INFO_BY_DEPT_ID,
    {
      variables: {
        dept_id: loginUserInfo?.data?.getAllAdditionalInfoByUserId?.dept_id,
      },
    }
  );
  useEffect(() => {
    let result = LoadEmployeeByDepartment?.data?.getAllAdditionalInfoByDeptId
      ?.filter((item) => item?.user_id != user?.id)
      ?.map((item) => {
        return {
          value: item?.rfid,
          label: item?.user?.name,
        };
      });
    let result2 = LoadHeadOfDepartment?.data?.getAllDepartments
      ?.filter((item) => item?.user_id != user?.id)
      ?.map((item) => {
        return {
          value: item?.user?.additionalInfo?.rfid,
          label: item?.user?.name,
        };
      });
    if (roles?.includes("Employee")) {
      setEmployeeByDepartment(result);
    } else if (
      roles?.includes("Head Of Department") ||
      roles?.includes("System Admin")
    ) {
      setEmployeeByDepartment(result2);
    }
  }, [
    LoadEmployeeByDepartment?.data?.getAllAdditionalInfoByDeptId?.length,
    LoadHeadOfDepartment?.data?.getAllDepartments?.length,
  ]);
  useEffect(() => {
    setAdditionalInfo(
      LoadAdditionalInfo?.data?.getAllAdditionalInfo?.find(
        (item) => item?.user_id == user?.id
      )
    );
    LoadAdditionalInfo?.refetch();
  }, [user?.id, LoadAdditionalInfo?.data?.getAllAdditionalInfo]);

  // leave type array

  const leaveTypeList = [
    {
      value: 1,
      label: `Casual (${additionalInfo?.casual})`,
    },
    {
      value: 2,
      label: `Anual (${additionalInfo?.anual})`,
    },
    {
      value: 3,
      label: `Sick (${additionalInfo?.sick})`,
    },
    {
      value: 4,
      label: `Menstrual (${additionalInfo?.menstrual})`,
    },
    {
      value: 5,
      label: `Maternity (${additionalInfo?.maternity})`,
    },
  ];

  // head of the department email
  const LoadAllDepartment = useQuery(GET_ALL_DEPARTMENT);

  // leave type for leave details show
  const leaveTypeListForEmail = [
    {
      value: 1,
      label: `Casual`,
    },
    {
      value: 2,
      label: `Anual`,
    },
    {
      value: 3,
      label: `Sick`,
    },
    {
      value: 4,
      label: `Menstrual`,
    },
    {
      value: 5,
      label: `Maternity`,
    },
    {
      value: 6,
      label: `Reimburse`,
    },
  ];

  const [userRfid, setUserRfid] = useState(
    employeeByDepartment?.find((item) => item?.value == updateLeaveData?.rfid)
      ? employeeByDepartment?.find(
          (item) => item?.value == updateLeaveData?.rfid
        )
      : ""
  );

  const selectedReliver = LoadApplicationDropdownData?.data?.relievers?.find(
    (item) => item?.value == updateLeaveData?.rfid
  );
  const selectLeave = LoadApplicationDropdownData?.data?.leaveTypes?.find(
    (item) => item?.value == updateLeaveData?.leave_type
  );

  // form onsubmit
  const [leave, setLeave] = useState([
    updateLeaveData?.start_date ? new Date(updateLeaveData?.start_date) : null,
    updateLeaveData?.end_date ? new Date(updateLeaveData?.end_date) : null,
  ]);
  const [startDate, endDate] = leave;

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      // leave_type: selectLeave || "",
    },
    // defaultValues: {
    //     'leave_type': leaveForm?.leaveTypes?.find(item => item?.value == updateLeaveData?.leave_type)
    // }
  });

  const [selectedLeavType, setSelectedLeavType] = useState(
    leaveForm?.leaveTypes?.find(
      (item) => item?.value == updateLeaveData?.leave_type
    )
      ? leaveForm?.leaveTypes?.find(
          (item) => item?.value == updateLeaveData?.leave_type
        )
      : ""
  );

  const leaveType = updateLeaveData?.id
    ? selectedLeavType
    : watch("leave_type");

  const [leaveTypeDay, setLeaveTypeDay] = useState(
    leaveType?.label?.split(" (")[1]?.split(")")[0]
  );
  useEffect(() => {
    if (leaveType?.label?.split(" (")[1]?.split(")")[0] !== "undefined") {
      setLeaveTypeDay(leaveType?.label?.split(" (")[1]?.split(")")[0]);
    } else {
      setLeaveTypeDay(
        moment
          .duration(
            new Date(updateLeaveData?.end_date) -
              new Date(updateLeaveData?.start_date)
          )
          .asDays() + 1
      );
    }
  }, [
    watch("leave_type")?.value,
    updateLeaveData?.id,
    selectedLeavType,
    selectLeave,
  ]);
  const getAllAdditionalInfoByRfid = useQuery(GET_ALL_ADDITIONAL_INFO_BY_RFID, {
    skip: true,
  });

  const onSubmit = async (value) => {
    setIsCreating(true);
    // exist any previous application checking within selected date
    const existApplicationId = [];
    const apllicantDateArray = dateRangeArray(
      moment(new Date(leave[0])).format("YYYY-MM-DD"),
      moment(new Date(leave[1])).format("YYYY-MM-DD")
    );
    apllicantDateArray?.map((existDate) => {
      existLeaveApplication?.map((item) => {
        if (item?.application_date == existDate) {
          existApplicationId.push(item?.application_id);
        }
      });
    });

    if ([...new Set(existApplicationId)]?.length > 0 && !updateLeaveData?.id) {
      // if exist any previous application within selected date
      isExistApllication(
        existApplicationId,
        getAllLeavesByUserId?.data?.getAllLeavesByUserId
      );
      setIsCreating(false);
    } else {
      // if not exist any previous application within selected date
      const rfidInfo = await getAllAdditionalInfoByRfid.refetch({
        rfid: watch("rfid")?.value ? watch("rfid")?.value : userRfid?.value,
      });

      const formData = {
        user_id: Number(user?.id),
        rfid: value?.rfid?.value,
        leave_reason: value?.reason,
        leave_type: Number(value?.leave_type?.value),
        images: JSON.stringify(images),
        leave_label: value?.leave_type?.label.toLowerCase()?.split(" (")?.[0],
        start_date: String(leave[0]),
        end_date: String(leave[1]),
      };

      if (updateLeaveData?.id) {
        const result = await updateLeave({
          variables: {
            ...formData,
            id: updateLeaveData?.id,
            user_id: updateLeaveData?.user_id,
            rfid: watch("rfid")?.value ? watch("rfid")?.value : userRfid?.value,
          },
        });
        if (result?.data?.updateLeave?.result_code == "000") {
          setIsCreating(false);
          successMessage(result?.data?.updateLeave?.status, 3000);
          history.push("/leave/history");
        }
        if (result?.data?.updateLeave?.result_code == "002") {
          setIsCreating(false);
          warningMessage(result?.data?.updateLeave?.status, 3000);
        }
      } else {
        const newApplicationBody = `
            <!DOCTYPE html>
                <html>
                <head>
                    <meta name="viewport" content="width=device-width, initial-scale=1">
                </head>
                <body>
                    <p>Dear ${
                      LoadAllDepartment?.data?.getAllDepartments
                        ?.find((item) => item?.id == additionalInfo?.dept_id)
                        ?.user?.name?.split(" ")[0]
                    } sir,</p>
                    <p>I am writing this email with reference to my remaining ${
                      leaveTypeListForEmail?.find(
                        (item) => item?.value == formData?.leave_type
                      )?.label
                    } leave quota. I will be taking leave following date:</p>
                    <table style="border-collapse: collapse; margin: 25px 0; font-size: 0.9em; font-family: sans-serif; min-width: 400px; box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);">
                        <thead>
                            <tr style="background-color: #009879; color: #ffffff; text-align: left;">
                                <th style="padding: 12px 15px;">Start</th>
                                <th style="padding: 12px 15px;">End</th>
                                <th style="padding: 12px 15px;">Day</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="border-bottom: 1px solid #dddddd;">
                                <td style="padding: 12px 15px;">${moment(
                                  formData?.start_date
                                ).format("DD MMMM YYYY")}</td>
                                <td style="padding: 12px 15px;">${moment(
                                  formData?.end_date
                                ).format("DD MMMM YYYY")}</td>
                                <td style="padding: 12px 15px;">${
                                  moment
                                    .duration(
                                      new Date(formData?.end_date) -
                                        new Date(formData?.start_date)
                                    )
                                    .asDays() + 1
                                }${
          moment
            .duration(
              new Date(formData?.end_date) - new Date(formData?.start_date)
            )
            .asDays() +
            1 >
          1
            ? " Days"
            : " Day"
        }</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><b>Reason</b>: <br /> ${formData?.leave_reason}</p>
                    <p className="mt-3"><b>Reliever: <br /></b>
          Name: ${rfidInfo?.data?.getAllAdditionalInfoByRfid[0]?.user?.name} (${
          rfidInfo?.data?.getAllAdditionalInfoByRfid[0]?.rfid
        }) <br />
          Email: ${
            rfidInfo?.data?.getAllAdditionalInfoByRfid[0]?.user?.email
          }</p>
                    <p>I request you to consider my leave request. During my absence, I can be reached at my email id(${
                      additionalInfo?.user?.email
                    }).</p>
                    <p>Best Regards,</p>
                    <p>${additionalInfo?.user?.name}</p>
                    <a href="https://office.ipageglobal.com/leave/mail">Dashboard here</a>
                </body>
            </html>`;

        const result = await createLeave({
          variables: {
            ...formData,
            subject_email: `Application for ${
              leaveTypeListForEmail?.find(
                (item) => item?.value == formData?.leave_type
              )?.label
            } leave`,
            text_email: `Please check office management dashboard.`,
            html_email: newApplicationBody,
            from_email: additionalInfo?.user?.email,
            // to_email: "tanvirahammed.ipage@gmail.com",
            to_email: LoadAllDepartment?.data?.getAllDepartments?.find(
              (item) => item?.id == additionalInfo?.dept_id
            )?.user?.email,
          },
        });
        if (result?.data?.createLeave?.result_code == "000") {
          setIsCreating(false);
          successMessage(result?.data?.createLeave?.status, 3000);
          history.push("/leave/history");
        }
        if (result?.data?.createLeave?.result_code == "002") {
          setIsCreating(false);
          warningMessage(result?.data?.createLeave?.status, 3000);
        }
      }
    }
  };

  useEffect(() => {
    if (leave[0] && leave[1]) {
      // weekend checking
      let weekendDates = [];
      const initialState = new Date(leave[0]);
      initialState.setDate(new Date(leave[0]).getDate());
      for (
        let date = initialState;
        date <= new Date(leave[1]);
        date.setDate(date.getDate() + 1)
      ) {
        if (date.getDay() < 5) {
          weekendDates.push(new Date(date.toString()));
        }
      }
      setLeaveLength(weekendDates?.length);
    }
  }, [leave[0], leave[1]]);

  useEffect(() => {
    if (leaveLength > leaveTypeDay) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [leaveTypeDay]);

  useEffect(() => {
    setSelectedLeavType(
      leaveForm?.leaveTypes?.find(
        (item) => item?.value == updateLeaveData?.leave_type
      )
    );
    if (
      watch("leave_type")
        ?.label?.toLowerCase()
        ?.split(" (")?.[1]
        ?.split(")")[0] !== "undefined"
    ) {
      setSelectedLeavType(watch("leave_type"));
    }
  }, [LoadAdditionalInfo?.data?.getAllAdditionalInfo, watch("leave_type")]);

  useEffect(() => {
    if (watch("rfid")?.label !== "undefined") {
      setUserRfid(watch("rfid"));
    }
  }, [watch("rfid")]);
  useEffect(() => {
    if (
      employeeByDepartment?.find(
        (item) => item?.value == updateLeaveData?.rfid
      ) !== "undefined"
    ) {
      setUserRfid(
        employeeByDepartment?.find(
          (item) => item?.value == updateLeaveData?.rfid
        )
      );
    }
  }, [
    employeeByDepartment?.find((item) => item?.value == updateLeaveData?.rfid),
  ]);
  const breadCrumbData = [
    {
      name: "Dashboard",
      link: "/",
    },
    {
      name: "Leave Apply ",
    },
  ];
  // const loading = LoadAllDepartment.loading;
  if (LoadApplicationDropdownData?.loading) {
    return <p>loading</p>;
  }
  return (
    <Col md={12}>
      <Card>
        <CardBody>
          <Col>
            <BreadCrumb breadCrumbData={breadCrumbData} />
          </Col>
          <Col md={12}>
            <h3 className="page-title">
              {updateLeaveData?.id
                ? "Update Leave"
                : "New application for leave"}
            </h3>
          </Col>
          <form className="form" onSubmit={handleSubmit(onSubmit)}>
            <Col md={6} className="mx-auto">
              <Row>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Leave</span>
                    <div className="form__form-group-field leave-calender">
                      <DatePicker
                        required
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Select date"
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                          setLeave(update);
                        }}
                        showWeekNumbers
                      />
                    </div>
                    {leave?.[1] && leaveLength <= leaveTypeDay
                      ? leave?.[1] && (
                          <span className="text-success">
                            <strong>
                              You are choose {leaveLength} days for leave
                            </strong>
                          </span>
                        )
                      : leave?.[1] &&
                        watch("leave_type") && (
                          <span className="text-danger">
                            <strong>
                              You are not eligible for {leaveLength}{" "}
                              {leaveLength > 1 ? "days " : "day "} leave
                            </strong>
                          </span>
                        )}
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Leave Type</span>
                    <div className="form__form-group-field ">
                      <FormField
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        name="leave_type"
                        control={control}
                        // value={selectedLeavType || selectLeave}
                        defaultValue={selectLeave}
                        component={SelectField}
                        options={leaveForm?.leaveTypes}
                        placeholder="Select Leave Type"
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Reliever</span>
                    <div className="form__form-group-field ">
                      <FormField
                        errors={errors}
                        rules={{ required: "This is required field" }}
                        name="rfid"
                        control={control}
                        value={userRfid || selectedReliver}
                        component={SelectField}
                        options={leaveForm?.relievers}
                        placeholder="Select Reliever"
                        defaultValue={selectedReliver}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <label className="form__form-group-label">Reason:</label>
                    <div className="form__form-group-field">
                      <FormField
                        name="reason"
                        component="textarea"
                        control={control}
                        type="text"
                        placeholder={`Write your reason for leave`}
                        style={{ minHeight: "125px" }}
                        defaultValue={updateLeaveData?.leave_reason}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="form__form-group">
                    <div className="form__form-group-field justify-content-end">
                      <label className="checkbox-btn">
                        <input
                          className="checkbox-btn__checkbox"
                          name="occupation"
                          component="input"
                          type="checkbox"
                          value="Others"
                          checked={isChecked}
                          onClick={(e) =>
                            setIsChecked((prevState) => !prevState)
                          }
                        />{" "}
                        <span
                          className="checkbox-btn__checkbox-custom"
                          style={{
                            background: "white",
                            borderColor: "#4ce1b6",
                          }}
                        >
                          <CheckIcon />
                        </span>
                        <span className="checkbox-btn__label">
                          Do you have attachment?
                        </span>
                      </label>
                    </div>
                  </div>
                </Col>
                {isChecked ? (
                  <Col md={12}>
                    {/* <div className="form__form-group leave-dropzone">
                      <FormField
                        onChange={async (e) => {
                          await e?.map(async (value) => {
                            const formData = new FormData();
                            formData.append("file", value);
                            formData.append("folderName", "leaves");
                            const file = await axios.post(
                              `${BASE_URL}/api/image/users/upload`,
                              formData,
                              {
                                headers: {
                                  "content-type": "multipart/form-data",
                                },
                              }
                            );
                            setImages((prevState) => [
                              ...prevState,
                              { image: file?.data?.data },
                            ]);
                          });
                          e = [];
                        }}
                        control={control}
                        name="files"
                        component={renderDropZoneMultipleField}
                      />
                    </div> */}
                    <div className="form__form-group leave-dropzone">
                      <FormField
                        onChange={async (e) => {
                          console.log(images);
                          if (images.length > 4) {
                            // Show warning if more than 5 images are selected
                            Swal.fire({
                              title: "Limit Exceeded",
                              text: "You can upload a maximum of 5 images.",
                              icon: "warning",
                              confirmButtonColor: "#d33",
                              confirmButtonText: "OK",
                            });
                            return; // Prevent further processing
                          }

                          // Process valid image selection
                          const uploadedImages = [];
                          await Promise.all(
                            e?.map(async (value) => {
                              const formData = new FormData();
                              formData.append("file", value);
                              formData.append("folderName", "leaves");
                              const file = await axios.post(
                                `${BASE_URL}/api/image/users/upload`,
                                formData,
                                {
                                  headers: {
                                    "content-type": "multipart/form-data",
                                  },
                                }
                              );
                              uploadedImages.push({ image: file?.data?.data });
                            })
                          );

                          // Update state with new images
                          setImages((prevState) => [
                            ...prevState,
                            ...uploadedImages,
                          ]);

                          // Reset the input
                          e = [];
                        }}
                        control={control}
                        name="files"
                        component={renderDropZoneMultipleField}
                      />
                    </div>
                  </Col>
                ) : null}
                {images?.length ? (
                  <Col md={12} className="flex">
                    {images?.map((img, index) => (
                      <div
                        className="dropzone__img"
                        key={index}
                        style={{ backgroundImage: `url(${img.image})` }}
                      >
                        {/* <p className="dropzone__img-name">{img.image}</p> */}
                        <p>
                          {img.image && (
                            <>
                              {/* Check the file type */}
                              {["jpg", "jpeg", "png", "gif"].includes(
                                img.image.split(".").pop().toLowerCase()
                              ) ? (
                                // Render image preview
                                <img
                                  src={`https://storage.googleapis.com/aunkur/leaves/${img.image}`} // Provide the full path to the image
                                  alt="Preview"
                                  style={{
                                    zIndex: "2000",
                                    width: "150px",
                                    height: "150px",
                                    objectFit: "cover",
                                    marginBottom: "10px",
                                  }}
                                />
                              ) : img.image.split(".").pop().toLowerCase() ===
                                "pdf" ? (
                                // Render PDF preview
                                <embed
                                  src={img.image} // Provide the full path to the PDF
                                  type="application/pdf"
                                  width="150px"
                                  height="150px"
                                  style={{
                                    marginBottom: "10px",
                                    border: "1px solid #ccc",
                                  }}
                                />
                              ) : (
                                // Unsupported file type
                                <span>Unsupported file format</span>
                              )}
                            </>
                          )}
                        </p>
                        <button
                          className="dropzone__img-delete"
                          type="button"
                          onClick={async (e) => {
                            await axios.post(
                              `${BASE_URL}/api/image/filesDeleted`,
                              {
                                filesToDelete: [img.image],
                              }
                            );
                            const removedImage = images?.filter(
                              (removeImage) => removeImage !== img
                            );
                            setImages(removedImage);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    ))}
                  </Col>
                ) : (
                  <></>
                )}

                <Col xs={12}>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button
                        type="button"
                        onClick={() => history.push("/leave/history")}
                      >
                        {" "}
                        Cancel{" "}
                      </Button>

                      {leave?.[1] &&
                      moment.duration(leave?.[1] - leave[0]).asDays() + 1 <=
                        leaveTypeDay ? (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={isCreating}
                          // disabled={false}
                        >
                          {" "}
                          {updateLeaveData?.id ? "Update" : "Apply"}{" "}
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          {" "}
                          {updateLeaveData?.id ? "Update" : "Apply"}{" "}
                        </Button>
                      )}
                    </ButtonToolbar>
                  </div>
                </Col>
              </Row>
            </Col>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default LeaveForm;
