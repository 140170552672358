export const jobTypeOptions = [
  { value: "full_time", label: "Full Time" },
  { value: "part_time", label: "Part Time" },
  { value: "intern", label: "Intern" },
  { value: "contractual", label: "Contractual" },
];
export const EmployeeClassificationOptions = [
  { value: "1st_class", label: "1st Class" },
  { value: "2nd_class", label: "2nd Class" },
  { value: "3rd_class", label: "3rd Class" },
];
export const workingTimeOptions = [
  { value: 1, label: "1 Hour" },
  { value: 2, label: "2 Hours" },
  { value: 3, label: "3 Hours" },
  { value: 4, label: "4 Hours" },
  { value: 5, label: "5 Hours" },
  { value: 6, label: "6 Hours" },
  { value: 7, label: "7 Hours" },
  { value: 8, label: "8 Hours" },
  { value: 9, label: "9 Hours" },
  { value: 10, label: "10 Hours" },
  { value: 11, label: "11 Hours" },
  { value: 12, label: "12 Hours" },
  { value: 13, label: "13 Hours" },
  { value: 14, label: "14 Hours" },
];
export const educationOptions = [
  { value: "Ssc", label: "SSC (Secondary School Certificate)" },
  { value: "Hsc", label: "HSC (Higher Secondary Certificate)" },
  { value: "Bsc", label: "BSc (Bachelor of Science)" },
  { value: "Msc", label: "MSc (Master of Science)" },
  { value: "BBA", label: "BBA (Bachelor of Business Administration)" },
  { value: "MBA", label: "MBA (Master of Business Administration)" },
  { value: "BA", label: "BA (Bachelor of Arts)" },
  { value: "MA", label: "MA (Master of Arts)" },
  { value: "LLB", label: "LLB (Bachelor of Laws)" },
  { value: "LLM", label: "LLM (Master of Laws)" },
  { value: "Diploma", label: "Diploma" },
  { value: "Phd", label: "PhD (Doctor of Philosophy)" },
  { value: "Md", label: "MD (Doctor of Medicine)" },
  { value: "Msc-engg", label: "MSc in Engineering" },
  { value: "Bsc-engg", label: "BSc in Engineering" },
];
